<template lang="pug">
  .not-found
    slot Ничего не найдено
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style lang="scss" scoped>
  .not-found {
    padding: 13px 15px;
    border: 1px solid #E4EAEF;
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    border-radius: 4px;
    background: $color-white;
  }
</style>

import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  computed: {
    ...mapGetters({
      'isAdmin': 'api/auth/isAdmin',
      'isSimpleUser': 'api/auth/isSimpleUser'
    })
  },
  methods: {
    /**
     * @param item {Object}
     * @returns {Object}
     */
    getStatusParams (item) {
      const currentDate = moment(new Date(Date.now()))
      let partnerStatus = item.partnerStatus || ''
      const isPaidTillAfter = moment(item.paidTill).isAfter(currentDate)

      switch (item.businessStatus) {
      case 'CREATED':
        if (partnerStatus === 'starting' && isPaidTillAfter && !item.deleted) {
          return {
            class: 'starting',
            value: 'STARTING',
            statusName: 'Инициализация аккаунта',
            enableEdit: false,
            enableDelete: false,
            enableAuth: false,
            enablePayment: false
          }
        }
        break
      case 'IN_PROGRESS':
        if (partnerStatus === 'notAuthorized' && !item.paidTill && !item.deleted) {
          return {
            class: 'payment-wait',
            value: 'PAYMENT_WAIT',
            statusName: 'Ожидает оплаты',
            enableEdit: true,
            enableDelete: true,
            enableAuth: false,
            enablePayment: true
          }
        }
        if (partnerStatus === 'notAuthorized' && isPaidTillAfter && !item.deleted) {
          return {
            class: 'loading',
            value: 'LOADING',
            statusName: null,
            enableEdit: false,
            enableDelete: false,
            enableAuth: false,
            enablePayment: false,
            enableRestore: false
          }
        }
        if (partnerStatus === 'blocked' && isPaidTillAfter && !item.deleted) {
          return {
            class: 'blocked',
            value: 'BLOCKED',
            statusName: 'Заблокирован',
            enableReference: true,
            enableEdit: true,
            enableDelete: true,
            enableAuth: true,
            enablePayment: false,
            enableRestore: false,
            enableSupport: false
          }
        }
        break
      case 'DELETED':
        return {
          class: 'deleted',
          value: 'DELETED',
          statusName: 'Удален',
          enableEdit: false,
          enableDelete: false,
          enableAuth: false,
          enablePayment: false,
          enableSupport: false,
          enableRestore: false
        }
      }

      if (this.isAdmin) {
        switch (item.businessStatus) {
        case 'CREATED':
          if (partnerStatus === 'notAuthorized' && isPaidTillAfter && !item.deleted) {
            return {
              class: 'not-authorized',
              value: 'NOT_AUTHORIZED',
              statusName: 'Не авторизован',
              enableEdit: true,
              enableDelete: true,
              enableAuth: true,
              enablePayment: false,
              enableGenerate: true
            }
          }
          if (partnerStatus === 'authorized' && isPaidTillAfter && !item.deleted) {
            return {
              class: 'authorized',
              value: 'AUTHORIZED',
              statusName: 'Авторизован',
              enableEdit: true,
              enableDelete: true,
              enableAuth: false,
              enablePayment: false
            }
          }
          if (partnerStatus === 'yellowCard' && !item.deleted) {
            return {
              class: 'yellow-card',
              value: 'YELLOW_CARD',
              statusName: 'Спам',
              enableEdit: false,
              enableDelete: true,
              enableAuth: false,
              enablePayment: false
            }
          }
          break
        case 'CREATION_ERROR':
          return {
            class: 'error',
            value: 'ERROR',
            statusName: 'Ошибка',
            enableEdit: false,
            enableDelete: true,
            enableAuth: false,
            enablePayment: false,
            enableSupport: true
          }
        }
      } else if (this.isSimpleUser) {
        switch (item.businessStatus) {
        case 'IN_PROGRESS':
          if (partnerStatus === 'notAuthorized' && !isPaidTillAfter && !item.deleted) {
            return {
              class: 'payment-wait',
              value: 'PAYMENT_WAIT',
              statusName: 'Ожидает оплаты',
              enableEdit: true,
              enableDelete: true,
              enableAuth: false,
              enablePayment: true
            }
          }
          if (partnerStatus === 'blocked' && !isPaidTillAfter && !item.deleted) {
            return {
              class: 'blocked',
              value: 'BLOCKED',
              statusName: 'Заблокирован',
              enableReference: true,
              enableEdit: true,
              enableDelete: true,
              enableAuth: false,
              enablePayment: true
            }
          }
          break
        case 'CREATED':
          if (partnerStatus === 'notAuthorized' && isPaidTillAfter && !item.deleted) {
            return {
              class: 'not-authorized',
              value: 'NOT_AUTHORIZED',
              statusName: 'Не авторизован',
              enableEdit: true,
              enableDelete: false,
              enableAuth: true,
              enablePayment: false
            }
          }
          if (partnerStatus === 'authorized' && isPaidTillAfter && !item.deleted) {
            return {
              class: 'authorized',
              value: 'AUTHORIZED',
              statusName: 'Авторизован',
              enableEdit: true,
              enableDelete: false,
              enableAuth: false,
              enablePayment: false
            }
          }
          if (partnerStatus === 'yellowCard' && !item.deleted) {
            return {
              class: 'yellow-card',
              value: 'YELLOW_CARD',
              statusName: 'Спам',
              enableEdit: false,
              enableDelete: true,
              enableAuth: false,
              enablePayment: false
            }
          }
          break
        case 'CREATION_ERROR':
          return {
            class: 'error',
            value: 'ERROR',
            statusName: 'Ошибка',
            enableEdit: false,
            enableDelete: false,
            enableAuth: false,
            enablePayment: false,
            enableSupport: true,
            enableReference: false
          }
        }
      }
    }
  }
}

<template lang="pug">
  .page.error
    span 404
</template>

<script>
export default {
  name: 'Error'
}
</script>

<style lang="scss" scoped>
  .page.error {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 100px);

    span {
      font-size: 150px;
      font-weight: 500;
      color: rgba($color-black-100, 0.3);
    }
  }
</style>

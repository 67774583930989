<template lang="pug">
  .page.registration
    transition(name="fadeUp" mode="out-in")
      RegistrationForm(v-if="showRegistrationForm" :key="0")
      EmailConfirmation(v-if="!showRegistrationForm" :key="1")
</template>

<script>
import { mapState } from 'vuex'
import RegistrationForm from '../components/RegistrationForm'
import EmailConfirmation from '../components/EmailConfirmation'

export default {
  name: 'Registration',
  components: { EmailConfirmation, RegistrationForm },
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      showRegistrationForm: state => state.auth.showRegistrationForm
    })
  },
  beforeDestroy () {
    this.$store.dispatch('auth/toggleRegistrationForm', true)
    this.$store.dispatch('auth/setUserEmail', '')
  }
}
</script>

<style lang="scss" scoped>
  .page.registration {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    min-height: calc(100vh - 131px);
  }
</style>

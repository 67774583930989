import Transactions from './views/Transactions'

export default [
  {
    name: 'transactions',
    path: '/transactions',
    component: Transactions,
    meta: {
      title: 'История платежей',
      auth: true
    }
  }
]

<template lang="html">
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.24334 1.21744C9.74859 0.92752 10.3709 0.92752 10.8761 1.21744L17.303 4.90594C17.8083 5.19586 18.1194 5.73165 18.1194 6.31149V13.6885C18.1194 14.2683 17.8083 14.8041 17.303 15.0941L10.8761 18.7826C10.3709 19.0725 9.74859 19.0725 9.24334 18.7826L2.81637 15.0941C2.31124 14.8041 2 14.2683 2 13.6885V6.31149C2 5.73165 2.31124 5.19586 2.81637 4.90594L9.24334 1.21744ZM9.02534 2.9562C9.47779 2.17716 10.609 2.17716 11.0615 2.9562L16.6749 12.6211C17.1275 13.4002 16.5619 14.374 15.6569 14.374H4.42984C3.52495 14.374 2.95936 13.4002 3.4118 12.6211L9.02534 2.9562ZM7.22245 8.7665C7.09489 8.63975 7.09489 8.43421 7.22245 8.30745L8.5368 7.00095C8.66423 6.8742 8.871 6.8742 8.99855 7.00095L10.3129 8.30745C10.4405 8.43421 10.4405 8.63975 10.3129 8.7665L8.99855 10.073C8.871 10.1998 8.66423 10.1998 8.5368 10.073L7.22245 8.7665ZM10.6488 9.01922C10.5212 9.14598 10.5212 9.35152 10.6488 9.47827L11.5561 10.3802C11.6836 10.507 11.8904 10.507 12.0178 10.3802L12.9251 9.47827C13.0527 9.35152 13.0527 9.14598 12.9251 9.01922L12.0178 8.1173C11.8904 7.99055 11.6836 7.99055 11.5561 8.1173L10.6488 9.01922ZM8.335 11.3239C8.20744 11.4506 8.20744 11.6562 8.335 11.7829L9.89298 13.3317C10.0205 13.4584 10.2273 13.4584 10.3549 13.3317L11.9129 11.7829C12.0404 11.6562 12.0404 11.4506 11.9129 11.3239L10.3549 9.77509C10.2273 9.64834 10.0205 9.64834 9.89298 9.77509L8.335 11.3239Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconAimylogic'
}
</script>

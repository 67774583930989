<template lang="pug">
  .input-component(:class="{ clearable, error, secondary, loading, password }")
    input(
      @input="onInput"
      @change="onChange"
      @focus="onFocus"
      @blur="onBlur"
      :value="value"
      :disabled="disabled || loading"
      :readonly="readonly"
      :autofocus="autofocus"
      :placeholder="getPlaceholder"
      :maxlength="maxlength"
      :type="getType"
      ref="input")

    transition(name="fade" :duration="80")
      span.button-clear(
        v-if="showClearButton"
        @click="clear"
        @keypress.enter="clear"
        tabindex="0")
        iconCross

    transition(name="fade" :duration="80")
      span.button-toggle-password(
        v-if="password && value && value.length"
        @click="togglePassword"
        @keypress.enter="togglePassword"
        tabindex="0")
        iconEyeClosed(v-if="showPassword")
        iconEyeOpened(v-if="!showPassword")

    .preloader(v-if="loading")
      Preloader
</template>

<script>
import { currency } from '@/utils/filters'
import Preloader from './Preloader'
import IconEyeOpened from './IconEyeOpened'
import IconCross from './IconCross'
import IconEyeClosed from './IconEyeClosed'

export default {
  name: 'Input',
  model: {
    prop: 'value',
    event: 'input'
  },
  components: {
    Preloader,
    IconEyeOpened,
    IconCross,
    IconEyeClosed
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: 'auto'
    },
    clearable: Boolean,
    password: Boolean,
    disabled: Boolean,
    error: Boolean,
    secondary: Boolean,
    readonly: Boolean,
    loading: Boolean,
    autofocus: Boolean,
    autocomplete: Boolean,
    number: Boolean,
    formatCurrency: {
      type: Boolean,
      default: true
    },
    maxlength: {
      type: [String, Number],
      default: null,
      validator (value) {
        const isValid = /[0-9]/g.test(value)
        if (!isValid) {
          console.warn('[Input component]: You can use only numbers/string numbers')
        }
        return isValid
      }
    }
  },
  data () {
    return {
      showPassword: false
    }
  },
  watch: {
    error () {
      this.error ?
        this.$refs.input.classList.add('transition-error') :
        this.$refs.input.classList.remove('transition-error')
    }
  },
  computed: {
    getType () {
      if (this.password) return 'password'
      return 'text'
    },
    getPlaceholder () {
      return this.placeholder.length && !this.loading ? this.placeholder : null
    },
    showClearButton () {
      return !this.disabled &&
        !this.loading &&
        !this.password &&
        this.clearable &&
        this.value &&
        this.value.length > 1
    }
  },
  methods: {
    onInput (event) {
      if (this.number) {
        event.target.value = event.target.value.replace(/\D/gmi, '')

        if (isNaN(parseInt(event.target.value))) {
          this.$emit('input', '')
          return
        }

        if (this.formatCurrency) {
          const filteredInput = currency(event.target.value)

          this.$emit('input', event.target.value)

          this.$nextTick(() => {
            event.target.value = filteredInput
          })
          return
        }
      }

      this.$emit('input', event.target.value)
    },

    onChange (event) {
      this.$emit('change', event.target.value)
    },

    onBlur (event) {
      this.$emit('blur', event.target.value)
    },

    onFocus (event) {
      this.$emit('focus', event.target.value)
    },

    clear () {
      this.$emit('input', '')
      this.$refs.input.focus()
      this.$emit('clear')
    },

    togglePassword () {
      this.showPassword ? this.hide() : this.show()
      this.$refs.input.focus()
    },

    hide () {
      this.$refs.input.type = 'password'
      this.showPassword = false
    },

    show () {
      this.$refs.input.type = 'text'
      this.showPassword = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .input-component {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 40px;;

    &.secondary {
      height: 38px;

      input {
        font-size: 14px;
        border: 0;

        &:focus {
          box-shadow: 0 0 0 2px #33B0EA inset;
        }
      }

      &.error {
        input {
          box-shadow: 0 0 0 2px #FF5449 inset;

          &:focus {
            box-shadow: 0 0 0 2px #FF5449 inset;
          }
        }
      }
    }

    &.clearable {
      input {
        padding-right: 11px;
      }
    }

    &.error {
      input {
        box-shadow: 0 0 0 1px #FF766D inset;
        border-color: #FF766D;

        &:focus {
          box-shadow: 0 0 0 1px #FF766D inset;
          border-color: #FF766D;
        }
      }
    }

    &.password {
      input {
        padding-right: 35px;
      }
    }

    input {
      width: 100%;
      height: 100%;
      background: #FFFFFF;
      border: solid 1px #CCD7E0;
      border-radius: 4px;
      color: #333333;
      padding: 12px 11px 13px 11px;
      transition: all ease 0.2s;
      font-size: 15px;
      line-height: 24px;
      font-weight: 300;
      outline: none;

      &:focus {
        border-color: #33B0EA;
        box-shadow: 0 0 0 1px #33B0EA inset;
      }

      &:disabled {
        border-color: #DEE5EA;
        background-color: #FAFBFB;
        color: #333333;
      }

      &::placeholder {
        color: #A3B1BC;
      }
    }

    .preloader {
      display: flex;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      padding-left: 12px;
      background-color: rgba(#FAFBFB, 0.7);
      border-radius: 5px;
    }

    .button-clear {
      position: absolute;
      right: 11px;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 12px;
      height: 14px;
      padding: 0;
      background-color: transparent;
      border: 0;
      cursor: pointer;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .button-toggle-password {
      position: absolute;
      right: 12px;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 20px;
      height: 20px;
      padding: 0;
      background-color: transparent;
      border: 0;
      cursor: pointer;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    ::v-deep {
      .button-clear {
        @include svg($color-gray-70);

        &:hover {
          @include svg(#FF766D);
        }
      }

      .button-toggle-password {
        @include svg($color-gray-70);

        &:hover {
          @include svg(#FF766D);
        }
      }
    }

    .transition-error {
      animation-duration: 0.5s;
      animation-timing-function: ease-in-out;
      animation-name: error;
    }

    @keyframes error {
      0% {
        transform: translateX(0);
      }
      6.5% {
        transform: translateX(-6px) rotateY(-9deg);
      }
      18.5% {
        transform: translateX(5px) rotateY(7deg);
      }
      31.5% {
        transform: translateX(-3px) rotateY(-5deg);
      }
      43.5% {
        transform: translateX(2px) rotateY(3deg);
      }
      50% {
        transform: translateX(0);
      }
    }
  }
</style>

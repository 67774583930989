<template lang="pug">
  .auth-layout
    transition(name="fade")
      Loader(v-if="isLoading")
    .layout-content
      HeaderAuth
      slot
      Footer
</template>

<script>
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import Header from '../components/header/index'
import Loader from '../components/loader/index'
import Footer from '../components/footer/index'
import HeaderAuth from '@/components/header/header-auth'

Vue.use(VueCookies)

export default {
  name: 'LayoutDefault',
  components: {
    HeaderAuth,
    Footer,
    Loader,
    Header
  },
  data () {
    return {
      isLoading: true
    }
  },
  mounted () {
    const sessionToken = this.$cookies.get('XSRF-TOKEN')

    if (sessionToken) {
      this.$router.replace('/')
      return
    }

    setTimeout(() => {
      this.isLoading = false
    }, 1000)
  }
}
</script>

<style lang="scss" scoped>
  .auth-layout {
    min-width: 1272px;
    min-height: 100vh;
    transition: padding-left 0.2s ease;
    background-color: #E7EDF0;

    .layout-content {
      min-height: 100%;
      margin: 0 20px;
    }
  }
</style>

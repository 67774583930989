import Vue from 'vue'
import axios from 'axios'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)

import router from '../router'

const qs = require('qs')

class API {
  constructor () {
    this.instance = axios.create({
      baseURL: '/api/',
      timeout: 6e5,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })

    this.instance.interceptors.response.use(
      this.handleSuccess,
      this.handleError
    )
  }

  handleSuccess (response) {
    return response.data
  }

  handleError (error) {
    const status = error.response.status

    switch (status) {
    case 401:
      Vue.$cookies.remove('XSRF-TOKEN')
      Vue.$cookies.remove('SESSION')
      if (router.currentRoute.path !== '/login') {
        Vue.$cookies.set('auto_logout_url', router.currentRoute.path, '30min')
        router.push('/login')
      }
      break
    }

    throw error
  }

  async request (method, url, data = {}) {
    const { sendSessionToken } = { sendSessionToken: true, ...data.options }

    const config = {
      method,
      url,
      params: data.params || {},
      headers: data.headers || {},
      data: data.data || null,
      ...(data.options || {})
    }

    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    config.headers['Content-Type'] = config?.headers['Content-Type'] || 'application/json'

    if (!sendSessionToken) {
      return this.instance.request(config)
    }

    config.headers['X-XSRF-TOKEN'] = Vue.$cookies.get('XSRF-TOKEN')

    // eslint-disable-next-line no-useless-catch
    try {
      return await this.instance.request(config)
    } catch (err) {
      throw err
    }
  }

  get (url, data = {}) {
    return this.request('GET', url, data)
  }

  post (url, data = {}) {
    return this.request('POST', url, data)
  }

  put (url, data = {}) {
    return this.request('PUT', url, data)
  }

  delete (url, data = {}) {
    return this.request('DELETE', url, data)
  }
}

export { API }
export default new API()

<template lang="pug">
  .add-integration-modal-component
    Modal(
      :value="show"
      title="Добавить связку"
      width="446px"
      @close="close"
      :loading="isLoading")
      ValidationObserver(ref="validation" tag="div")
        .fields
          .field
            span.label-modal.required Номер WhatsApp
            ValidationProvider(
              :rules="{required: true}"
              name="Sender Name"
              v-slot="{ errors }"
              mode="eager"
              tag="div")
              Select(
                v-model="form.senderName"
                :options="msisdns"
                :loading="isMsisdnsLoading"
                :error="!!errors.length")

          .field
            span.label-modal.required Webhook Aimylogic
            ValidationProvider(
              :rules="{required: true, max: 255, regex: callbackRegex}"
              name="Callback"
              v-slot="{ errors }"
              mode="eager"
              tag="div")
              Input(v-model.trim="form.callback" @input="processCallbackInput" :error="!!errors.length")

      template(slot="actions")
        .flex
          Button(
            @click="submit"
            :loading="isLoading"
            size="block") Добавить
          Button(
            @click="close"
            :disabled="isLoading"
            size="block"
            color="secondary") Отмена
</template>

<script>
import { mapActions } from 'vuex'
import { callbackRegex } from '../utils/constants'

export default {
  name: 'ModalAddIntegration',
  props: {
    show: Boolean
  },
  data () {
    return {
      isLoading: false,
      isMsisdnsLoading: false,
      callbackRegex,
      form: {
        senderName: '',
        callback: ''
      },
      msisdns: []
    }
  },
  watch: {
    show () {
      if (this.show) {
        this.$refs.validation.reset()
        this.form = JSON.parse(JSON.stringify(this.$options.data().form))
        this.loadAvailableMsisdns()
      }
    }
  },
  methods: {
    ...mapActions('api/account', ['getAvailableMsisdns', 'setCallback']),

    processCallbackInput () {
      this.form.callback = this.form.callback.replace(/ /g, '')
    },
    async submit () {
      const isValid = await this.$refs.validation.validate()
      if (!isValid) return

      this.isLoading = true

      this.processCallbackInput()

      const data = {
        senderName: this.form.senderName,
        callback: this.form.callback
      }

      try {
        await this.setCallback({ data })
        this.close()
        this.$emit('update')
        this.$notify.add({
          content: 'Интеграция добавлена',
          type: 'success'
        })
      } catch (err) {
        console.warn('Error', err)
        this.$notify.add('Произошла ошибка. Пожалуйста, попробуйте еще раз.')
      } finally {
        this.isLoading = false
      }
    },
    async loadAvailableMsisdns () {
      this.isMsisdnsLoading = true

      try {
        this.msisdns = await this.getAvailableMsisdns()
      } catch (err) {
        console.warn('Error', err)
        this.$notify.add('Произошла ошибка при попытке получить номера телефонов')
      } finally {
        this.isMsisdnsLoading = false
      }
    },
    close () {
      this.$emit('update:show', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .add-integration-modal-component {
    .fields {
      .field {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
</style>

<template lang="pug">
  .tariff-card-component(@click="onClick" :class="{'active': value === tariff.id}")
    span.name {{ tariff.name }}
    span.price {{ getTariffCost }}
</template>

<script>
import { datePluralization } from '@/utils/date-pluralization'

export default {
  name: 'TariffCard',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    tariff: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    getTariffCost () {
      if (this.tariff.cost > 0) return this.tariff.cost + '₽/мес'
      const duration = this.tariff.duration
      let durationArr = []
      if (duration.days) {
        durationArr.push(datePluralization(duration.days, 'days'))
      }
      if (duration.months) {
        durationArr.push(datePluralization(duration.months, 'months'))
      }
      if (duration.years) {
        durationArr.push(datePluralization(duration.years, 'years'))
      }

      return durationArr.join(' ') + ' бесплатно'
    }
  },
  methods: {
    onClick () {
      this.$emit('change', this.tariff.id)
    }
  }
}
</script>

<style lang="scss" scoped>
  .tariff-card-component {
    display: block;
    padding: 13px 15px;
    border-radius: 6px;
    border: 1px solid $color-gray-20;
    cursor: pointer;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
    //margin-bottom: 16px;
    width: 100%;

    &.active {
      border-color: $color-light-blue-100;
      box-shadow: 0 0 0 1px inset $color-light-blue-100;
    }

    .name {
      display: block;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 6px;
    }

    .price {
      display: block;
    }
  }
</style>

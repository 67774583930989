<template lang="pug">
  .input-preloader-component
    div
    div
    div
</template>

<script>
export default {
  name: 'InputPreloader'
}
</script>

<style lang="scss" scoped>
  .input-preloader-component {
    display: inline-flex;
    align-items: center;

    div {
      border-radius: 50%;
      width: 6px;
      height: 6px;
      margin-right: 2px;
      background-color: #dce1e4;

      &:last-child {
        margin-right: 0;
      }

      &:nth-child(1) {
        animation: preloader 0.4s ease infinite alternate-reverse;
      }

      &:nth-child(2) {
        animation: preloader 0.4s ease infinite alternate-reverse 0.2s;
      }

      &:nth-child(3) {
        animation: preloader 0.4s ease infinite alternate-reverse 0.4s;
      }
    }

    @keyframes preloader {
      0% {
        background-color: #dce1e4;
      }
      100% {
        background-color: #8c9dab;
      }
    }
  }
</style>

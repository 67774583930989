import Login from './views/Login'
import Registration from './views/Registration'
import ConfirmEmail from './views/ConfirmEmail'
import ResetPassword from './views/ResetPassword'

export default [
  {
    name: 'login',
    path: '/login',
    component: Login,
    meta: {
      title: 'Вход в Weasy',
      layout: 'LayoutAuth'
    }
  },
  {
    name: 'registration',
    path: '/registration',
    component: Registration,
    meta: {
      title: 'Регистрация',
      layout: 'LayoutAuth'
    }
  },
  {
    name: 'confirm-email',
    path: '/confirm-email',
    component: ConfirmEmail,
    meta: {
      title: 'Weasy - Подтверждение e-mail',
      layout: 'LayoutAuth'
    }
  },
  {
    name: 'reset-password',
    path: '/reset-password',
    component: ResetPassword,
    meta: {
      title: 'Weasy - Сброс пароля',
      layout: 'LayoutAuth'
    }
  }
]

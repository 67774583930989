<template lang="pug">
  .documents-modal-component
    Modal(
      :value="show"
      title="Документы"
      width="666px"
      @close="close")
      .documents
        a(href="https://docs.weasy.pro/oferta/oferta-na-okazanie-uslug-po-dostupu-k-servisu-weasy/" target="_blank") Публичная оферта
        // a(href="/") Условия использования сервиса
        a(href="https://docs.weasy.pro/politika-konfidencialnosti/politika-konfidencialnosti-weasy/" target="_blank") Политика конфиденциальности
</template>

<script>
export default {
  name: 'ModalDocuments',
  props: {
    show: Boolean
  },
  methods: {
    close () {
      this.$emit('update:show', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .documents-modal-component {
    .documents {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      a {
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
</style>

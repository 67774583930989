<template lang="pug">
  .page.reset-password
    .form-reset-password
      form(action="")
        span.form-title Придумайте новый пароль
        .flex.j-center
          Input.input-password(
            v-model="form.password"
            v-tooltip.right="{content: getValidationTooltipContent, forceShow: isMobile ? false : showTooltip}"
            @input="validateRegistrationPasswordSteps"
            @change="validatePassword"
            @focus="showTooltip = true"
            @blur="showTooltip = false"
            placeholder="Пароль"
            :error="hasPasswordError"
            password)
        .flex.center
          Button(@click.prevent="submit" :loading="isLoading") Сохранить
</template>

<script>
import { mapActions } from 'vuex'
import mixinPasswordValidation from '@/mixins/password-validation'

export default {
  name: 'ResetPassword',
  mixins: [mixinPasswordValidation],
  data () {
    return {
      isLoading: false,
      hasPasswordError: false,
      form: {
        email: '',
        password: '',
        code: ''
      }
    }
  },
  methods: {
    ...mapActions('api/auth', ['resetPassword', 'whoami']),

    validatePassword () {
      this.hasPasswordError = !this.validateRegistrationPasswordSteps()
      return this.validateRegistrationPasswordSteps()
    },
    async submit () {
      const passCheck = this.validatePassword()
      if (!passCheck) return
      this.isLoading = true

      const data = {
        email: this.form.email,
        secret: this.form.code,
        newPassword: this.form.password
      }

      try {
        await this.resetPassword(data)
        this.$notify.add({
          type: 'success',
          content: 'Новый пароль сохранен'
        })

        await this.$router.push('/login')
      } catch (err) {
        console.warn('Error', err)
        if (err.response.status === 400) {
          this.$notify.add('Неверная ссылка для сброса пароля')
        } else {
          this.$notify.add('Произошла ошибка. Пожалуйста, попробуйте еще раз.')
        }
      }
    }
  },
  mounted () {
    if (this.$route.query.email && this.$route.query.code) {
      this.form.email = this.$route.query.email
      this.form.code = this.$route.query.code
    } else {
      // this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss" scoped>
  .page.reset-password {
    min-height: calc(100vh - 131px);
    display: flex;
    align-items: center;
    justify-content: center;

    .form-reset-password {
      max-width: 500px;
      width: 100%;
      padding: 30px;
      background-color: $color-white;
      border-radius: 8px;

      .form-title {
        display: block;
        margin-bottom: 30px;
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        text-align: center;
      }

      .input-password {
        margin-bottom: 20px;
        max-width: 360px;
        width: 100%;
      }
    }
  }
</style>

<template lang="pug">
  .header-component
    .header-content
      .support-info
        span Техподдержка:&nbsp;
          a.link.link-underline(href="mailto:support@weasy.pro") support@weasy.pro

      // BalanceInfo

      UserMenu(@openSettings="showSettingsModal = true" @openDocuments="showDocumentsModal = true")

    ModalSettings(:show.sync="showSettingsModal")

    ModalDocuments(:show.sync="showDocumentsModal")
</template>

<script>
import UserMenu from './user-menu'
import ModalSettings from './modal-settings'
import ModalDocuments from './modal-documents'
import BalanceInfo from './balance-info'

export default {
  name: 'Header',
  components: {
    BalanceInfo,
    ModalDocuments,
    ModalSettings,
    UserMenu
  },
  data () {
    return {
      showSettingsModal: false,
      showDocumentsModal: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .header-component {
    position: fixed;
    display: flex;
    align-items: center;
    top: 0;
    left: 220px;
    width: calc(100% - 220px);
    height: 64px;
    background-color: $color-white;
    box-shadow: 20px 0 20px rgba(219, 221, 226, 0.5);
    z-index: 40;

    .header-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 12px 0 16px;
      flex: 1;

      .support-info {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        margin-right: 16px;

        span {
          font-size: 14px;
          line-height: 1px;
        }

        a {
          line-height: 1;
        }
      }
    }
  }
</style>

<template lang="pug">
  label.radio-component(
    :class="{ 'is-disabled': disabled }"
    @keyup.space="$refs.input.click()"
    tabindex="0")
    input.input(
      :checked="isChecked"
      :disabled="disabled"
      :name="name"
      :value="value"
      type="radio"
      @change="onChange"
      @click="$emit('click', $event)"
      ref="input"
      tabindex="-1")
    .radio
    .radio-text
      slot
</template>

<script>
export default {
  name: 'FormRadio',
  model: {
    prop: 'model',
    event: 'change'
  },
  props: {
    name: {
      type: [String, Number],
      default: ''
    },
    model: {
      type: [String, Boolean, Number, Object, Array, Function],
      default: false
    },
    value: {
      type: [String, Boolean, Number, Object, Array, Function],
      default: false
    },
    disabled: Boolean
  },
  computed: {
    isMultiple () {
      return ['string', 'number', 'object', 'array'].includes(typeof this.model)
    },
    isChecked () {
      return this.isMultiple ? this.model === this.value : this.model
    }
  },
  methods: {
    onChange (e) {
      this.$emit('change', this.isMultiple ? e.target.value : e.target.checked)
    }
  }
}
</script>

<style lang="scss" scoped>
  .radio-component {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    font-size: 15px;
    line-height: 22px;

    &:hover {
      .radio {
        border-color: #a3b5c5;
      }
    }

    &.is-disabled {
      cursor: default;
    }

    .input {
      position: absolute;
      overflow: hidden;
      width: 1px;
      height: 1px;
      clip: rect(0, 0, 0, 0);

      &:checked ~ .radio {
        border-color: $color-light-blue-100;
        background-color: #D8F5FF;

        &:after {
          transform: scale(1);
        }
      }

      &:disabled ~ .radio {
        border-color: $color-gray-50;
        background-color: #F3F5F6;

        &:after {
          background-color: #97A8B3;
        }
      }
    }

    .radio {
      position: relative;
      display: flex;
      height: 18px;
      width: 18px;
      box-sizing: border-box;
      flex: 0 0 18px;
      align-items: center;
      justify-content: center;
      border: 1px solid $color-gray-50;
      margin-right: 8px;
      background-color: $color-white;
      border-radius: 50%;
      transition: all 0.2s ease;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 8px;
        height: 8px;
        margin: auto;
        background-color: $color-black-100;
        border-radius: 50%;
        transform: scale(0);
        transition: all 0.2s ease;
      }
    }
  }
</style>

<template lang="html">
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0001 1.6665C5.40425 1.6665 1.66675 5.404 1.66675 9.99984C1.66675 11.329 1.98758 12.6415 2.59591 13.8207L1.70425 17.0123C1.60425 17.3748 1.70841 17.7623 1.97091 18.029C2.17091 18.2248 2.43758 18.3332 2.70841 18.3332C2.80425 18.3332 2.89591 18.3207 2.98758 18.2957L6.17925 17.404C7.35842 18.0123 8.67508 18.3332 10.0001 18.3332C14.5959 18.3332 18.3334 14.5957 18.3334 9.99984C18.3334 5.404 14.5959 1.6665 10.0001 1.6665ZM14.3167 13.0707C14.1334 13.5832 13.2334 14.079 12.8292 14.1165C12.4251 14.154 12.0459 14.2998 10.1876 13.5665C7.94592 12.6832 6.52925 10.3873 6.42091 10.2373C6.30841 10.0915 5.52091 9.04567 5.52091 7.95817C5.52091 6.87484 6.09175 6.3415 6.29175 6.12067C6.49591 5.89984 6.73342 5.84567 6.87925 5.84567C7.02925 5.84567 7.17508 5.84567 7.30425 5.84984C7.45842 5.85817 7.63341 5.8665 7.80008 6.23317C7.99591 6.6665 8.42508 7.75817 8.47925 7.87067C8.53342 7.979 8.57091 8.10817 8.49591 8.254C8.42508 8.39984 8.38758 8.4915 8.27508 8.62067C8.16675 8.74984 8.04591 8.90817 7.94591 9.00817C7.83758 9.1165 7.72091 9.23734 7.85008 9.45817C7.97925 9.679 8.42092 10.3998 9.07508 10.9832C9.91675 11.7332 10.6251 11.9665 10.8459 12.079C11.0667 12.1873 11.1959 12.1707 11.3251 12.0207C11.4542 11.8748 11.8751 11.379 12.0251 11.1582C12.1709 10.9373 12.3167 10.9748 12.5209 11.0498C12.7209 11.1207 13.8042 11.654 14.0251 11.7665C14.2459 11.8748 14.3917 11.929 14.4459 12.0207C14.5001 12.1123 14.5001 12.554 14.3167 13.0707Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconWhatsApp'
}
</script>

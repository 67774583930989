import Vue from 'vue'
import Vuex from 'vuex'
import ui from './modules/ui'
import api from './api'

import { modules as selectedModules } from '../modules'

const vuexModules = {}
selectedModules.forEach(module => {
  if (module.store) {
    try {
      vuexModules[module.name] = module.store
    } catch (e) {
      console.warn(e)
    }
  }
})

export const state = ({})

export const getters = {}

export const actions = {}

export const mutations = {}

export const modules = {
  api,
  ui,
  ...vuexModules
}

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules
})

export default {
  methods: {
    /**
     * @param item {Object}
     * @returns {Boolean}
     */
    isAccountBlocked (item) {
      const status = item.partnerStatus || ''
      return status.toUpperCase() === 'BLOCKED'
    }
  }
}

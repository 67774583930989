import Accounts from './views/Accounts'

export default [
  {
    name: 'accounts',
    path: '/',
    component: Accounts,
    meta: {
      title: 'Аккаунты в WhatsApp',
      auth: true
    }
  }
]

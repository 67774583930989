import { render, staticRenderFns } from "./ModalAddIntegration.vue?vue&type=template&id=311074fc&scoped=true&lang=pug&"
import script from "./ModalAddIntegration.vue?vue&type=script&lang=js&"
export * from "./ModalAddIntegration.vue?vue&type=script&lang=js&"
import style0 from "./ModalAddIntegration.vue?vue&type=style&index=0&id=311074fc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "311074fc",
  null
  
)

export default component.exports
<template lang="pug">
  .registration-form-component
    span.title Регистрация

    form(@submit.prevent="submit")
      .field
        Input(
          v-model="form.name"
          @change="validateName"
          @blur="form.name = form.name.trim()"
          @input="hasNameError = false"
          :error="hasNameError"
          placeholder="ФИО")
      .field
        Input(
          v-model="form.email"
          @change="validateEmail"
          @input="hasEmailError = false"
          placeholder="Email"
          :error="hasEmailError")
      .field(v-tooltip.right="{content: getValidationTooltipContent, forceShow: isMobile ? false : showTooltip}")
        Input(
          v-model="form.password"
          @input="validateRegistrationPasswordSteps"
          @change="validatePassword"
          @focus="showTooltip = true"
          @blur="showTooltip = false"
          placeholder="Придумайте пароль"
          :error="!!hasPasswordError"
          password)

      .button-group
        Button(
          @click="submit"
          :loading="isLoading"
          type="submit") Зарегистрироваться

    .documents-section
      span
        | Нажимая «Зарегистрироваться» вы соглашаетесь с&nbsp;
        a(href="https://docs.weasy.pro/oferta" target="_blank") Условиями <br> использования сервиса
        | &nbsp;и&nbsp;
        a(href="https://docs.weasy.pro/politika-konfidencialnosti/politika-konfidencialnosti-weasy" target="_blank") Политикой конфиденциальности

    .flex.center
      Button.link-registration(to="/login" text) Вернуться ко входу в систему
</template>

<script>
import { mapActions } from 'vuex'
import mixinPasswordValidation from '@/mixins/password-validation'

export default {
  name: 'RegistrationForm',
  mixins: [
    mixinPasswordValidation
  ],
  data () {
    return {
      isLoading: false,
      isMobile: false,
      hasEmailError: false,
      hasNameError: false,
      form: {
        name: '',
        email: '',
        password: ''
      }
    }
  },
  methods: {
    ...mapActions('api/auth', ['register']),

    validateName () {
      this.hasNameError = !this.form.name.length
      return !!this.form.name.length
    },
    validatePassword () {
      this.hasPasswordError = !this.validateRegistrationPasswordSteps()
      return this.validateRegistrationPasswordSteps()
    },
    validateEmail () {
      // email is not empty
      if (!this.form.email.length) {
        this.hasEmailError = true
        return false
      }
      // email is from 5 to 100 symbols
      if (!(/^.{5,100}$/.test(this.form.email))) {
        this.hasEmailError = true
        return false
      }
      // email is x@y.z where x,y,z any non space ASCII symbols
      if (!(/^[!-~]+@[!-~]+\.[!-~]+$/.test(this.form.email))) {
        this.hasEmailError = true
        return false
      }
      this.hasEmailError = false
      return true
    },
    validateForm () {
      if (this.isLoading) {
        return
      }

      const nameCheck = this.validateName()
      const emailCheck = this.validateEmail()
      const passCheck = this.validatePassword()

      return nameCheck && emailCheck && passCheck
    },
    async submit () {
      const isValid = this.validateForm()
      if (this.form.email.length && this.hasEmailError) {
        this.$notify.add('Введен некорректный e-mail адрес.')
      }
      if (!isValid) return

      this.isLoading = true

      try {
        const data = {
          clientName: this.form.name,
          password: this.form.password,
          email: this.form.email
        }
        await this.register(data)
        await this.$store.dispatch('auth/setUserEmail', this.form.email)
        await this.$store.dispatch('auth/toggleRegistrationForm', false)
      } catch (err) {
        console.warn('Error', err)
        let errorMessage = 'Возникла ошибка, пожалуйста, попробуйте ещё раз.'
        if (err.response.status === 409) {
          errorMessage = `Электронная почта <strong>${this.form.email}</strong> уже зарегистрирована`
        }
        this.$notify.add(errorMessage)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss">
  .registration-tooltip-validation {
    &.success {
      svg {
        fill: $color-green-100;
      }
    }

    svg {
      margin-right: 8px;
      width: 11px;
      fill: #ACC5D2;
    }
  }
</style>

<style lang="scss" scoped>
  .registration-form-component {
    padding: 30px 30px;
    max-width: 480px;
    width: 100%;
    background-color: $color-white;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(153, 169, 181, 0.2);

    .title {
      display: block;
      text-align: center;
      font-size: 24px;
      line-height: 32px;
      font-weight: 400;
      margin-bottom: 30px;
    }

    form {
      padding: 0 30px;

      .field {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .documents-section {
      margin-top: 20px;
      font-size: 13px;
      line-height: 22px;
      color: #8C9EAB;
      text-align: center;

      a {
        color: #8C9EAB;
        border-bottom: 1px solid #8C9EAB;
      }
    }

    .tip-box-row {
      span {
        font-size: 14px;
        line-height: 22px;
        padding-left: 8px;
      }
    }

    .button-group {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .link-registration {
      margin: 20px 0 0 0;
    }

    ::v-deep {
      .form {
        .input-component input {
          height: 44px;
        }
      }

      .tip-box-row {
        @include svg(#ACC5D2);

        &.success {
          @include svg($color-green-100);
        }

        svg {
          width: 11px;
        }
      }
    }
  }
</style>

<template lang="pug">
  .email-confirmation-component
    span.title Регистрация личного кабинета

    .email-info
      span Для завершения регистрации перейдите по ссылке в письме, отправленной на&nbsp;
        b {{ userEmail }}

    .button-check-email(v-if="getEmailSite(userEmail)")
      Button.button-verify(
        @click="openInNewTab"
        size="long") Проверить почту
</template>

<script>
import { mapState } from 'vuex'
import mixinGetEmailSite from '../mixins/get-email-site'

export default {
  name: 'EmailConfirmation',
  mixins: [mixinGetEmailSite],
  computed: {
    ...mapState({
      userEmail: state => state.auth.userEmail
    })
  },
  methods: {
    openInNewTab () {
      const emailSite = this.getEmailSite(this.userEmail)
      window.open(emailSite, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
  .email-confirmation-component {
    padding: 30px 57px;
    max-width: 500px;
    width: 100%;
    background-color: $color-white;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(153, 169, 181, 0.2);

    .title {
      display: block;
      text-align: center;
      font-size: 24px;
      line-height: 32px;
      font-weight: 400;
      margin-bottom: 30px;
    }

    .email-info {
      text-align: center;
      font-size: 15px;
      line-height: 26px;
    }

    .button-check-email {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }

    .button-auth {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }
  }
</style>

<template lang="pug">
  .navbar-body-component
    nav.navbar-items
      component(
        v-for="(item, i) in menuItems"
        :key="i"
        :to="item.to || null"
        :href="item.href || null"
        :target="item.href ? '_blank' : null"
        :is="item.to ? 'router-link' : 'a'").navbar-item
        component(:is="getIcon(item.icon)")
        span {{ item.name }}
    // button.button-collapse(@click="toggleNavbar")
      IconChevron
      span Свернуть
</template>

<script>
import { menuItems } from './menu-items'
import IconSiren from '../icons/IconSiren'
import IconPeople from '../icons/IconPeople'
import IconTemplate from '../icons/IconTemplate'
import IconChevron from './icons/IconChevron'

export default {
  name: 'NavbarBody',
  components: {
    IconSiren,
    IconPeople,
    IconTemplate,
    IconChevron
  },
  data () {
    return {
      menuItems
    }
  },
  methods: {
    getIcon (iconName) {
      if (!iconName) return null

      try {
        const test = require('./icons/' + iconName)
        return test.default
      } catch {
        return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .navbar-body-component {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 64px);

    .navbar-items {
      margin-bottom: 24px;

      .navbar-item {
        display: flex;
        align-items: center;
        padding: 13px 18px;
        background-color: #3E4D60;
        color: #9EA6AF;
        transition: background-color 0.2s ease, color 0.2s ease;

        &:hover, &.router-link-exact-active {
          background-color: #2F3E51;
          color: $color-white;

          ::v-deep {
            @include svg($color-white);
          }
        }

        span {
          transition: opacity 0.2s ease;
        }

        svg {
          flex-shrink: 0;
          margin-right: 12px;
        }

        ::v-deep {
          @include svg(#9EA6AF);
        }
      }
    }

    .button-collapse {
      width: 100%;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      padding-left: 18px;
      height: 46px;
      color: #9EA6AF;
      transition: color 0.2s ease;

      &:hover {
        color: $color-white;

        ::v-deep {
          @include svg($color-white);
        }
      }

      span {
        transition: opacity 0.2s ease;
      }

      svg {
        transition: transform 0.2s ease;
        flex-shrink: 0;
        margin-right: 12px;
      }

      ::v-deep {
        @include svg(#9EA6AF);
      }
    }
  }
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Directives
import outside from './directives/outside'
import tooltip from './directives/tooltip'

// Plugins & etc
import './assets/scss/main.scss'
import './plugins/tab-focusing'
import './components/global-components'

// Packages
import VueMask from 'v-mask'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import Notifications from './components/notification/plugin'


for (let rule in rules) {
  extend(rule, {
    ...rules[rule]
  })
}

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

Vue.use(outside, tooltip)
Vue.use(VueMask)
Vue.use(Notifications)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')

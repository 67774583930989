/**
 * @param value {Number}
 * @param type {String}
 */

export const datePluralization = (value, type) => {
  if (!['days', 'months', 'years'].includes(type)) return value

  let plurStr = ''
  if (value % 10 === 1 && value !== 11) {
    switch (type) {
    case 'days': plurStr = 'день'
      break
    case 'months': plurStr = 'месяц'
      break
    case 'years': plurStr = 'год'
      break
    }
  } else if ([2, 3, 4].includes(value % 10)) {
    switch (type) {
    case 'days': plurStr = 'дня'
      break
    case 'months': plurStr = 'месяца'
      break
    case 'year': plurStr = 'года'
      break
    }
  } else {
    switch (type) {
    case 'days': plurStr = 'дня'
      break
    case 'months': plurStr = 'месяцев'
      break
    case 'years': plurStr = 'лет'
      break
    }
  }
  return `${value} ${plurStr}`
}

export default {
  datePluralization
}

export default {
  methods: {
    /**
      * Получение адреса сайта из email пользователя
      * @param {string} email
      * @returns {string, null}
    */
    getEmailSite (email) {
      if (!email) return null
      const regexp = /(@mail.ru|@gmail.com|@yandex.ru|@ya.ru)/gm
      if (regexp.test(email)) {
        return `http://${email.replace(/^[^@]+@/, '')}`
      }
      return null
    }
  }
}

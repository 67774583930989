<template lang="html">
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 0C7.51675 0 7.125 0.391751 7.125 0.875V7.125H0.875C0.391751 7.125 0 7.51675 0 8C0 8.48325 0.391751 8.875 0.875 8.875H7.125V15.125C7.125 15.6082 7.51675 16 8 16C8.48325 16 8.875 15.6082 8.875 15.125V8.875H15.125C15.6082 8.875 16 8.48325 16 8C16 7.51675 15.6082 7.125 15.125 7.125H8.875V0.875C8.875 0.391751 8.48325 0 8 0Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'IconPlus'
}
</script>

<template lang="pug">
  .loader-component
    .loader-wrapper
      .title
        .spinner
        img(src="/img/logo-title.svg" title="logo title")
</template>

<script>
export default {
  name: 'Loader'
}
</script>

<style lang="scss" scoped>
  .loader-component {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $color-white;
    z-index: 100;

    .loader-wrapper {
      animation: bounceIn 0.5s ease 1;
    }

    .title {
      position: relative;
      overflow: hidden;
      width: 54px;
      height: 78px;
      display: flex;
      align-items: center;
      justify-content: center;
      animation: title 0.2s ease-in-out 1 forwards 0.2s;
      // transform: scale(1.5);
      transform-origin: left;
      box-shadow: -20px 0 10px -10px white inset;

      img {
        transform: translateX(70px);
        width: 192px;
        height: 88px;
        animation: img 0.25s ease-in-out 1 forwards 0.2s;
      }

      .spinner {
        position: absolute;
        top: 0;
        right: 15px;
        width: 10px;
        height: 10px;
        border: 2px solid;
        border-radius: 50%;
        border-color: transparent #34A853 #34A853 #34A853;
        opacity: 0;
        animation: spinner 1s ease infinite, spinner-fade 0.3s ease forwards 0.3s;
      }
    }

    @keyframes spinner {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes spinner-fade {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes title {
      0% {
        width: 54px;
      }
      100% {
        width: 250px;
        transform: scale(1);
      }
    }

    @keyframes img {
      0% {
        // opacity: 0;
        transform: translateX(70px);
        //transform: translateY(40px);
      }
      100% {
        // opacity: 1;
        transform: translateX(0);
        // transform: translateY(0);
      }
    }

    @keyframes bounceIn {
      from,
      20%,
      40%,
      60%,
      80%,
      to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      }

      0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
      }

      20% {
        transform: scale3d(1.1, 1.1, 1.1);
      }

      40% {
        transform: scale3d(0.9, 0.9, 0.9);
      }

      60% {
        opacity: 1;
        transform: scale3d(1.03, 1.03, 1.03);
      }

      80% {
        transform: scale3d(0.97, 0.97, 0.97);
      }

      to {
        opacity: 1;
        transform: scale3d(1, 1, 1);
      }
    }

    @keyframes bounceIn {
      from,
      20%,
      40%,
      60%,
      80%,
      to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      }

      0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
      }

      20% {
        transform: scale3d(1.1, 1.1, 1.1);
      }

      40% {
        transform: scale3d(0.9, 0.9, 0.9);
      }

      60% {
        opacity: 1;
        transform: scale3d(1.03, 1.03, 1.03);
      }

      80% {
        transform: scale3d(0.97, 0.97, 0.97);
      }

      to {
        opacity: 1;
        transform: scale3d(1, 1, 1);
      }
    }
  }
</style>

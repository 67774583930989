<template lang="pug">
  .page.confirm-email
    .preloader
      Preloader
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ConfirmEmail',
  data () {
    return {
      email: '',
      secret: ''
    }
  },
  methods: {
    ...mapActions('api/auth', ['confirmEmail']),

    async confirm () {
      const data = {
        email: this.email,
        secret: this.secret
      }

      try {
        await this.confirmEmail(data)
        this.$notify.add({
          type: 'success',
          content: 'Электронная почта подтверждена.'
        })
      } catch (err) {
        console.warn('Error', err)
        this.$notify.add('Произошла ошибка при попытке подтвердить e-mail.')
      } finally {
        await this.$router.push('/login')
      }
    }
  },
  mounted () {
    if (this.$route.query.email && this.$route.query.code) {
      this.email = this.$route.query.email
      this.secret = this.$route.query.code
      this.confirm()
    } else {
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss" scoped>
  .page.confirm-email {
    .preloader {
      height: calc(100vh - 80px);
    }
  }
</style>

<template lang="pug">
  .add-account-modal-component
    Modal(
      :value="show"
      title="Добавить аккаунт"
      width="666px"
      @close="close"
      :loading="isLoading")
      ValidationObserver(ref="validation" tag="div")
        .fields
          .field
            ValidationProvider(
              :rules="{required: true, min: 11, max: 17}"
              name="Номер телефона"
              v-slot="{ errors }"
              mode="eager"
              tag="div")
              Input(
                v-model="form.senderName"
                v-mask="'#'.repeat(17)"
                :error="!!errors.length")
              span.info-message С этого номера будут отправляться сообщения вашим клиентам в WhatsApp
          .field
            Input(v-model="form.name" maxlength="100")
            span.info-message Это видите только вы

      template(slot="actions")
        .flex
          Button(
            @click="submit"
            :loading="isLoading"
            size="long") Добавить
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ModalAddAccount',
  props: {
    show: Boolean
  },
  data () {
    return {
      isLoading: false,
      form: {
        senderName: '',
        name: ''
      }
    }
  },
  watch: {
    show () {
      if (this.show) {
        this.$refs.validation.reset()
        this.form = JSON.parse(JSON.stringify(this.$options.data().form))
      }
    }
  },
  methods: {
    ...mapActions('api/account', ['createAccount']),

    async submit () {
      const isValid = await this.$refs.validation.validate()
      if (!isValid) return

      this.isLoading = true

      const data = {
        senderName: this.form.senderName,
        name: this.form.name || ''
      }

      try {
        await this.createAccount({ data })
        this.close()
        this.$emit('update')
        this.$notify.add({
          content: 'Новый аккаунт добавлен',
          type: 'success'
        })
      } catch (err) {
        console.warn('Error', err)
        let errorMessage = 'Произошла ошибка. Пожалуйста, попробуйте еще раз.'
        if (err.response.status === 409) {
          errorMessage = `Аккаунт с номером ${this.form.senderName} уже существует`
        }
        this.$notify.add(errorMessage)
      } finally {
        this.isLoading = false
      }
    },
    close () {
      this.$emit('update:show', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .add-account-modal-component {
    .fields {
      display: flex;

      .field {
        flex: 1;

        & + .field {
          margin-left: 20px;
        }
      }
    }

    ::v-deep {
      .modal-component .modal-container {
        .modal-content {
          padding-bottom: 16px;
        }
      }
    }
  }
</style>

<template lang="pug">
  .settings-modal-component
    Modal(
      :value="show"
      title="Настройки профиля"
      width="666px"
      @close="close"
      :loading="isLoading")

      .flex.center(v-if="isDataLoading")
        Preloader(static)

      template(v-if="!isDataLoading")
        ValidationObserver(ref="validation" tag="div")
          .fields.row.wrap
            .col.col-6
              span.label.required ФИО
              ValidationProvider(
                :rules="{required: true}"
                name="clientName"
                v-slot="{ errors }"
                mode="lazy"
                tag="div")
                Input(v-model.trim="form.clientName" :error="!!errors.length")
            .col.col-6
              span.label Email
              ValidationProvider(
                :rules="{email: true}"
                name="e-mail"
                v-slot="{ errors }"
                mode="lazy"
                tag="div")
                Input(
                  v-model="form.email"
                  :error="!!errors.length"
                  disabled)
            .col.col-6
              span.label Наименование организации
              Input(v-model.trim="form.companyName")
            .col.col-6
              span.label Новый пароль
              Input(
                v-model="form.password"
                @input="validateRegistrationPasswordSteps"
                @change="validatePassword"
                v-tooltip.right="{content: getValidationTooltipContent, handler: 'focus', capture: true}"
                :error="hasPasswordError"
                password)

        //.checkbox-payment
          Checkbox(v-model="showBankInfo") Оплачивать услуги сервиса по безналу

        //template(v-if="showBankInfo")
          .bank-info
            .radio-buttons
              Radio.radio-button ИП
              Radio ООО
            .fields.row.wrap
              .col.col-6
                span.label.required Наименование организации
                Input
              .col.col-6
                span.label.required ИНН
                Input
              .col.col-6
                span.label.required ОРГНИП
                Input
              template
                .col.col-6
                  span.label.required КПП
                  Input
                .col.col-6
                  span.label.required ФИО директора
                  Input
                .col.col-6
                  span.label.required Действует на основании
                  Input
              .col.col-6
                span.label.required Email для выставления счетов
                Input

        template(slot="actions")
          .flex
            Button(
              @click="submit"
              :loading="isLoading") Сохранить
            Button(
              @click="close"
              :disabled="isLoading"
              color="secondary") Отмена
</template>

<script>
import { mapActions } from 'vuex'
import mixinPasswordValidation from '@/mixins/password-validation'

export default {
  name: 'ModalSettings',
  mixins: [mixinPasswordValidation],
  props: {
    show: Boolean
  },
  data () {
    return {
      isDataLoading: false,
      isLoading: false,
      showBankInfo: false,
      client: {},
      form: {
        clientName: '',
        email: '',
        companyName: '',
        password: ''
      }
    }
  },
  watch: {
    async show () {
      if (this.show) {
        this.form = JSON.parse(JSON.stringify(this.$options.data().form))
        await this.loadClient()
        if (Object.keys(this.client).length) {
          this.form.clientName = this.client.clientName
          this.form.email = this.client.email
          this.form.companyName = this.client.companyName
        }
        this.$refs.validation.reset()
      }
    }
  },
  methods: {
    ...mapActions('api/client', ['getClient', 'updateClient']),

    validatePassword () {
      this.hasPasswordError = !this.validateRegistrationPasswordSteps()
      return this.validateRegistrationPasswordSteps()
    },
    async submit () {
      const isValid = await this.$refs.validation.validate()
      if (!isValid || this.hasPasswordError) return

      this.isLoading = true

      const data = {
        clientName: this.form.clientName,
        email: this.form.email,
        ...(this.form.companyName !== this.client.companyName) && { companyName: this.form.companyName },
        ...(this.form.password) && { password: this.form.password }
      }

      try {
        await this.updateClient({ data })
        this.$notify.add({
          content: 'Данные профиля успешно изменены',
          type: 'success'
        })
        this.close()
      } catch (err) {
        const error = err?.response?.data?.error?.msg || ''
        console.log(err.response)
        let errorMessage = 'Возникла ошибка, пожалуйста, попробуйте ещё раз.'
        if (error === 'Passwords match') {
          errorMessage = 'Ошибка. Пароль совпадает с текущим'
        }
        this.$notify.add(errorMessage)
        console.warn('Error', err)
      } finally {
        this.isLoading = false
      }
    },
    async loadClient () {
      this.isDataLoading = true

      try {
        this.client = await this.getClient()
      } catch (err) {
        this.$notify.add('Произошла ошибка. Пожалуйста, попробуйте еще раз.')
        this.close()
        console.warn('Error', err)
      } finally {
        this.isDataLoading = false
      }
    },
    close () {
      this.$emit('update:show', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .settings-modal-component {
    .fields {
      margin-bottom: -20px;

      .col {
        margin-bottom: 20px;
      }
    }

    .bank-info {
      margin-top: 20px;

      .radio-buttons {
        display: flex;
        margin-bottom: 20px;

        .radio-button {
          margin-right: 16px;
        }
      }
    }

    .checkbox-payment {
      margin-top: 20px;
    }
  }
</style>

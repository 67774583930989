/*
  Формат объявления:
  v-tooltip.%position%="{
    content: <String>,
    forceShow: <Boolean>,
    caption: <Boolean>,
    type: <String>,
    handler: <String>,
    disabled: <Boolean>
  }"

  v-tooltip.%position%="<String>"
*/

import Vue from 'vue'
import '../assets/scss/_tooltip.scss'

const ALLOWABLE_POSITIONS = ['top', 'bottom', 'left', 'right']
const TOOLTIP_OFFSET = 14

let fadeTimeout = null

const validate = (value, silent = false) => {
  // Если не указали контент типа String
  if (!value) {
    if (silent) return false
    console.warn('[v-tooltip]: Empty tooltip value')
    return false
  }

  // Проверка что параметры [String, Number, Object]
  if (
    !['string', 'object', 'number'].includes(typeof value) ||
    Array.isArray(value)
  ) {
    if (silent) return false
    console.warn('[v-tooltip]: Value must be [String, Number, Object]')
    return false
  }

  // Проверка что указан content в Object
  if (
    typeof value === 'object' &&
    !Array.isArray(value) &&
    (typeof value.content === 'undefined' || value.content === null)
  ) {
    if (silent) return false
    console.warn('[v-tooltip]: You should provide content')
    return false
  }

  // Проверка что forceShow Boolean
  if (typeof value === 'object' &&
    'forceShow' in value &&
    typeof value.forceShow !== 'boolean') {
    console.warn('[v-tooltip]: forceShow must be Boolean')
    return false
  }

  return true
}

const updateElement = (el, binding, silent = true) => {
  // Обновляем данные тултипа
  el.__value__ = binding.value

  if (!validate(el.__value__, silent)) return

  el.__modifiers__ = binding.modifiers
  el.__type__ = binding.value.type || null
  el.__capture__ = binding.value.capture || false
  el.__handler__ = binding.value.handler || 'hover'

  // Обновляем отображение тултипа в зависимости от изменения forceShow
  if (el.__showTooltip__) {
    if (binding.value.forceShow === true) {
      el.__showTooltip__()
    } else if (binding.value.forceShow === false) {
      el.__hideTooltip__()
    }
  }

  // Обновляем текст, тип и позицию тултипа
  if (el.__updateValue__) el.__updateValue__()
  if (el.__updateType__) el.__updateType__()
  if (el.__updateTooltipPosition__) el.__updateTooltipPosition__()
  if (el.__removeEventListeners__) el.__removeEventListeners__()
  if (el.__addEventListeners__) el.__addEventListeners__()
}

const destroy = (el) => {
  if (el.__removeEventListeners__) el.__removeEventListeners__()
  if (el.__hideTooltip__) el.__hideTooltip__()

  el.__updateValue__ = null
  el.__updateType__ = null
  el.__showTooltip__ = null
  el.__hideTooltip__ = null
  el.__toggleOnClick__ = null
  el.__updateTooltipPosition__ = null
  el.__addEventListeners__ = null
}

Vue.directive('tooltip', {
  bind: (el, binding) => {
    updateElement(el, binding, true)
  },

  inserted: (el, binding) => {
    let tooltipRoot = document.createElement('div')
    let tooltipContainer = document.createElement('div')
    let tooltipContentWrapper = document.createElement('span')
    const id = 'tooltip_' + Date.now() + Math.floor(Math.random() * 100)

    const getTooltipText = (value) => {
      if (value.content) {
        return value.content
      }

      if (['string', 'number'].includes(typeof value)) {
        return value
      }

      return ''
    }

    const getElPosition = (modifiers) => {
      if (!modifiers) return 'top'

      const positions = Object.keys(modifiers).filter(pos => ALLOWABLE_POSITIONS.includes(pos) && pos)

      return positions.length ? positions[0] : 'top'
    }

    const updateTooltipPosition = () => {
      if (!document.body.contains(tooltipRoot)) return

      const elCoords = el.getBoundingClientRect()
      const tooltipCoords = tooltipRoot.getBoundingClientRect()
      const elPosition = getElPosition(el.__modifiers__)

      switch (elPosition) {
      case 'top':
        tooltipRoot.style.top = elCoords.top - tooltipCoords.height - TOOLTIP_OFFSET + 'px'
        tooltipRoot.style.left = elCoords.left + elCoords.width / 2 - tooltipCoords.width / 2 + 'px'
        break
      case 'bottom':
        tooltipRoot.style.top = elCoords.top + elCoords.height + TOOLTIP_OFFSET + 'px'
        tooltipRoot.style.left = elCoords.left + elCoords.width / 2 - tooltipCoords.width / 2 + 'px'
        break
      case 'left':
        tooltipRoot.style.top = elCoords.top + elCoords.height / 2 - tooltipCoords.height / 2 + 'px'
        tooltipRoot.style.left = elCoords.left - tooltipCoords.width - TOOLTIP_OFFSET + 'px'
        break
      case 'right':
        tooltipRoot.style.top = elCoords.top + elCoords.height / 2 - tooltipCoords.height / 2 + 'px'
        tooltipRoot.style.left = elCoords.left + elCoords.width + TOOLTIP_OFFSET + 'px'
        break
      }

      ALLOWABLE_POSITIONS.forEach(position => tooltipContainer.classList.remove(position))
      tooltipContainer.classList.add(elPosition)
    }

    const updateValue = () => {
      tooltipContentWrapper.innerHTML = ''
      tooltipContentWrapper.innerHTML = getTooltipText(el.__value__)
    }

    const updateType = () => {
      if (el.__type__ !== 'danger') {
        tooltipContainer.classList.remove('danger')
      } else {
        tooltipContainer.classList.add('danger')
      }
    }

    const showTooltip = () => {
      if (!validate(el.__value__)) return
      if (document.body.contains(tooltipRoot)) return
      if (el.__value__.disabled) return

      clearTimeout(fadeTimeout)
      tooltipRoot.setAttribute('id', id)
      tooltipRoot.classList.add('tooltip-component')
      tooltipContainer.classList.add('tooltip-window')
      tooltipContainer.appendChild(tooltipContentWrapper)
      tooltipRoot.appendChild(tooltipContainer)
      updateType()
      updateValue()
      document.body.appendChild(tooltipRoot)
      updateTooltipPosition()
      document.addEventListener('scroll', updateTooltipPosition)
      if (typeof el.__value__.forceShow !== 'boolean') {
        document.addEventListener('click', outsideClose)
      }
    }

    const outsideClose = (e) => {
      if (!el.contains(e.target) && el !== e.target) {
        hideTooltip()
      }
    }

    const hideTooltip = () => {
      if (!document.body.contains(tooltipRoot)) return
      tooltipRoot.classList.add('fade-out')
      setTimeout(() => {
        document.removeEventListener('scroll', updateTooltipPosition)
        tooltipRoot.classList.remove('fade-out')
        document.body.removeChild(tooltipRoot)
        document.removeEventListener('click', outsideClose)
      }, 40)
    }

    const toggleOnClick = () => {
      document.body.contains(tooltipRoot) ? hideTooltip() : showTooltip()
    }

    const addEventListeners = () => {
      window.addEventListener('resize', el.__updateTooltipPosition__)
      window.addEventListener('scroll', el.__updateTooltipPosition__)

      switch (typeof el.__value__.forceShow !== 'boolean' && el.__handler__) {
      case 'hover':
        el.addEventListener('mouseenter', el.__showTooltip__, el.__capture__)
        el.addEventListener('mouseleave', el.__hideTooltip__, el.__capture__)
        break
      case 'click':
        el.__toggleOnClick__ = toggleOnClick
        el.addEventListener('click', el.__toggleOnClick__, el.__capture__)
        break
      case 'focus':
        el.addEventListener('focus', el.__showTooltip__, el.__capture__)
        el.addEventListener('blur', el.__hideTooltip__, el.__capture__)
        break
      }
    }

    const removeEventListeners = () => {
      document.removeEventListener('scroll', el.__updateTooltipPosition__)
      window.removeEventListener('resize', el.__updateTooltipPosition__)
      window.removeEventListener('scroll', el.__updateTooltipPosition__)
      el.removeEventListener('mouseenter', el.__showTooltip__, el.__capture__)
      el.removeEventListener('mouseleave', el.__hideTooltip__, el.__capture__)
      el.removeEventListener('click', el.__toggleOnClick__, el.__capture__)
      el.removeEventListener('focus', el.__showTooltip__, el.__capture__)
      el.removeEventListener('blur', el.__hideTooltip__, el.__capture__)
    }

    el.__value__ = binding.value
    el.__modifiers__ = binding.modifiers

    if (binding.value) {
      el.__type__ = binding.value.type || null
      el.__handler__ = binding.value.handler || 'hover'
      el.__capture__ = binding.value.capture || false
    }

    el.__updateValue__ = updateValue
    el.__updateType__ = updateType
    el.__showTooltip__ = showTooltip
    el.__hideTooltip__ = hideTooltip
    el.__updateTooltipPosition__ = updateTooltipPosition
    el.__addEventListeners__ = addEventListeners
    el.__removeEventListeners__ = removeEventListeners

    addEventListeners()

    if (binding.value && binding.value.forceShow === true) {
      showTooltip()
      removeEventListeners()
    }

    validate(binding.value)
  },

  componentUpdated (el, binding) {
    updateElement(el, binding)
  },

  unbind: el => {
    destroy(el)
  }
})

export default {}

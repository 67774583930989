<template lang="html">
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.9998 2.50008C3.62975 2.50008 2.5 3.62834 2.5 4.99854V15.8296C2.5 16.7512 3.2455 17.4965 4.16677 17.4965H15.8337C16.755 17.4965 17.5002 16.7512 17.5002 15.8296V7.49834C17.5002 6.57709 16.755 5.83189 15.8337 5.83189H4.9998C4.52807 5.83189 4.16677 5.46976 4.16677 4.99854C4.16677 4.52681 4.52807 4.16514 4.9998 4.16514H15.8337C16.1331 4.16869 16.413 4.01222 16.566 3.75186C16.7157 3.49165 16.7157 3.17275 16.566 2.91208C16.413 2.65186 16.1331 2.49593 15.8337 2.50008H4.9998ZM15.0004 10.8307C15.4593 10.8307 15.8337 11.2052 15.8337 11.6631C15.8337 12.123 15.4593 12.4971 15.0004 12.4971C14.5411 12.4971 14.1668 12.123 14.1668 11.6631C14.1668 11.2052 14.5411 10.8307 15.0004 10.8307Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconTransactions'
}
</script>

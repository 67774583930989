<template lang="pug">
  .page.integrations
    .header.content-section
      .page-header
        h1 Интеграции с Aimylogic

    .flex.center(v-if="isPageDataLoading")
      Preloader(static)

    template(v-if="!isPageDataLoading")
      .button-group
        Button(
          @click="showAddModal = true"
          color="success"
          size="m"
          squared) Добавить

      Table(
        :headers="headers"
        :items="items"
        :pagination.sync="pagination"
        @update:pagination="loadAccounts"
        :loading="isLoading"
        darker)
        template(slot="items")
          tr(v-for="(item, i) in items" :key="i")
            td {{ item.senderName }}
            td {{ item.callback }}
            td
              .flex.a-center.j-end
                button.button-edit(@click="openEditModal(item)")
                  IconPencil
                button.button-delete(@click="openDeleteModal(item)")
                  IconTrash

    ModalAddIntegration(
      :show.sync="showAddModal"
      @update="loadAccounts")

    ModalEditIntegration(
      :show.sync="showEditModal"
      :item="currentEditItem"
      @update="loadAccounts")

    ModalDeleteIntegration(
      :show.sync="showDeleteModal"
      :item="currentDeleteItem"
      @update="loadAccounts")
</template>

<script>
import ModalAddIntegration from '../components/ModalAddIntegration'
import ModalDeleteIntegration from '../components/ModalDeleteIntegration'
import { mapActions } from 'vuex'
import ModalEditIntegration from '../components/ModalEditIntegration'

export default {
  name: 'Integrations',
  components: { ModalEditIntegration, ModalDeleteIntegration, ModalAddIntegration },
  data () {
    return {
      isPageDataLoading: true,
      isLoading: false,
      showAddModal: false,
      showDeleteModal: false,
      showEditModal: false,
      headers: [
        { caption: 'Телефон' },
        { caption: 'Webhook Aimylogic' },
        { caption: '' }
      ],
      items: [],
      currentDeleteItem: {},
      currentEditItem: {},
      pagination: {
        page: 1,
        perPage: 10,
        total: 0
      }
    }
  },
  methods: {
    ...mapActions('api/account', ['getAccounts']),

    openDeleteModal (item) {
      this.currentDeleteItem = JSON.parse(JSON.stringify(item))
      this.showDeleteModal = true
    },
    openEditModal (item) {
      this.currentEditItem = JSON.parse(JSON.stringify(item))
      this.showEditModal = true
    },
    async loadAccounts () {
      this.isLoading = true

      try {
        const params = {
          withCallback: true,
          page: this.pagination.page,
          per_page: this.pagination.perPage
        }

        const { items, total } = await this.getAccounts({ params })

        this.items = items
        this.pagination.total = total

        if (total > 0 && items.length === 0 && this.pagination.page !== 1) {
          this.pagination.page--
          await this.loadAccounts()
        }
      } catch (err) {
        console.warn('Error', err)
        this.$notify.add('Произошла ошибка. Пожалуйста, попробуйте еще раз.')
      } finally {
        this.isLoading = false
        this.isPageDataLoading = false
      }
    }
  },
  created () {
    this.loadAccounts()
  }
}
</script>

<style lang="scss" scoped>
  .page.integrations {
    .header {
      margin: 16px 0;
    }

    .button-group {
      margin-bottom: 16px;
    }

    .button-edit {
      margin-right: 14px;
    }

    .button-delete, .button-edit {
      width: 20px;
      height: 20px;
      color: $color-gray-60;
      transition: color 0.1s ease;

      &:hover {
        color: #33B0EA;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    ::v-deep {
      .table-component .table-wrapper table {
        table-layout: fixed;
        width: 100%;

        tr {
          th {
            &:nth-child(1) {
              width: 300px;
            }
            &:nth-child(3) {
              width: 112px;
            }
          }
        }
      }
    }
  }
</style>

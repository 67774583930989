<template lang="pug">
  .forgot-password-form
    span.title {{ getTitle }}

    template(v-if="!emailSent")
      ValidationObserver(ref="validation" tag="div")
        form(@submit.prevent="submit")
          ValidationProvider(
            :rules="{required: true, email: true}"
            name="e-mail"
            v-slot="{ errors }"
            mode="lazy"
            tag="div")
            .field
              Input.input-email(
                v-model="email"
                placeholder="Email"
                :error="!!errors.length")
        .button-submit
          Button(@click="submit" :loading="isLoading") Далее

    template(v-if="emailSent")
      span.form-info Ссылка для восстановления пароля отправлена на
        =" "
        span.email {{ email }}

      .button-check-mail(v-if="getEmailSite(email)")
        Button(@click="openInNewTab" fullwidth) Проверить почту

    .button-back(v-if="!emailSent")
      Button(@click="close" text) Вернуться ко входу в систему
</template>

<script>
import { mapActions } from 'vuex'
import getEmailSite from '../mixins/get-email-site'

export default {
  name: 'ForgotPasswordForm',
  mixins: [getEmailSite],
  data () {
    return {
      isLoading: false,
      emailSent: false,
      email: ''
    }
  },
  computed: {
    getTitle () {
      return this.emailSent ? 'Проверьте почту' : 'Восстановление пароля'
    }
  },
  methods: {
    ...mapActions('api/auth', ['requestResetPassword']),

    async submit () {
      const isValid = await this.$refs.validation.validate()
      const isEmailIncorrect = this.$refs.validation.fields['e-mail'].failed
      if (this.email.length && isEmailIncorrect) {
        this.$notify.add('Введен некорректный e-mail адрес.')
      }
      if (!isValid) return

      this.isLoading = true

      const data = {
        email: this.email
      }

      try {
        await this.requestResetPassword(data)
        this.emailSent = true
      } catch (err) {
        this.$notify.add('Произошла ошибка. Пожалуйста, попробуйте еще раз.')
        console.warn('Error', err)
      } finally {
        this.isLoading = false
      }
    },
    openInNewTab () {
      const emailSite = this.getEmailSite(this.email)
      window.open(emailSite, '_blank')
    },
    close () {
      this.$emit('close')
    }
  },
  mounted () {
    this.emailSent = false
  }
}
</script>

<style lang="scss" scoped>
  .forgot-password-form {
    padding: 30px 48px;
    max-width: 500px;
    width: 100%;
    background-color: $color-white;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(153, 169, 181, 0.2);

    form {
      margin-bottom: 20px;
    }

    .title {
      display: block;
      text-align: center;
      font-size: 24px;
      line-height: 32px;
      font-weight: 400;
      margin-bottom: 20px;
    }

    .field {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }

    .button-submit, .button-back {
      display: flex;
      justify-content: center;
    }

    .form-info {
      display: block;
      text-align: center;
      font-size: 15px;
      line-height: 26px;

      .email {
        font-weight: 500;
      }
    }

    .input-email {
      max-width: 360px;
      width: 100%;
    }

    .button-check-mail {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }

    .button-back {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
  }
</style>

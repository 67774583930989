import { render, staticRenderFns } from "./navbar-body.vue?vue&type=template&id=e8fc934c&scoped=true&lang=pug&"
import script from "./navbar-body.vue?vue&type=script&lang=js&"
export * from "./navbar-body.vue?vue&type=script&lang=js&"
import style0 from "./navbar-body.vue?vue&type=style&index=0&id=e8fc934c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8fc934c",
  null
  
)

export default component.exports
export const state = ({
  userEmail: '',
  showRegistrationForm: true
})

export const getters = {}

export const actions = {
  toggleRegistrationForm ({ commit }, email) {
    commit('TOGGLE_REGISTRATION_FORM', email)
  },
  setUserEmail ({ commit }, email) {
    commit('SET_USER_EMAIL', email)
  }
}

export const mutations = {
  TOGGLE_REGISTRATION_FORM (state, value) {
    state.showRegistrationForm = value
  },
  SET_USER_EMAIL (state, email) {
    state.userEmail = email
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

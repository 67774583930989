export default {
  data () {
    return {
      showTooltip: false,
      isMobile: false,
      hasPasswordError: false,
      passwordRepeatError: false,
      passwordValidation: {
        minLength: false,
        lowerLetter: false,
        upperLetter: false,
        digit: false,
        onlyAscii: false,
        isShown: false
      },
      iconCheckmark: '<svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.7379 0.717942L10.2383 0.246889C9.88996 -0.0815691 9.32568 -0.0824214 8.97811 0.24509L3.90357 5.02975L2.02172 3.25547C1.67435 2.92786 1.11007 2.92881 0.761493 3.25746L0.261983 3.72842C-0.0864892 4.05697 -0.0874934 4.589 0.259975 4.9166L3.27059 7.75494C3.44543 7.91979 3.6749 8.00131 3.90427 7.99998C4.13314 8.00103 4.36211 7.91931 4.53664 7.75475L10.74 1.90603C11.0873 1.57843 11.0867 1.04659 10.7379 0.717942Z" class="fill"/></svg>'
    }
  },
  computed: {
    getValidationTooltipContent () {
      let content = ''
      this.passwordTip.forEach(tip => {
        content += `<span class="registration-tooltip-validation ${tip.value ? 'success' : ''}">${this.iconCheckmark}${tip.text}</span><br />`
      })
      return content
    },
    passwordTip () {
      return [
        {
          text: 'Минимальная длина 8 символов',
          value: this.passwordValidation.minLength
        },
        {
          text: 'Буква в нижнем регистре',
          value: this.passwordValidation.lowerLetter
        },
        {
          text: 'Буква в верхнем регистре',
          value: this.passwordValidation.upperLetter
        },
        {
          text: 'Цифра',
          value: this.passwordValidation.digit
        },
        {
          text: 'Только латиница',
          value: this.passwordValidation.onlyAscii
        }
      ]
    }
  },
  methods: {
    validateRegistrationPasswordSteps () {
      let isErrored = false
      // password is not empty
      if (!this.form.password) {
        this.passwordValidation.onlyAscii = false
        this.passwordValidation.lowerLetter = false
        this.passwordValidation.upperLetter = false
        this.passwordValidation.digit = false
        this.passwordValidation.minLength = false
        return false
      }
      // password contains only non space ascii symbols
      if (!(/^[!-~]+$/.test(this.form.password))) {
        isErrored = true
        this.passwordValidation.onlyAscii = false
      } else {
        this.passwordValidation.onlyAscii = true
      }
      // password is 6 or more characters
      if (!(/^.{8,}$/.test(this.form.password))) {
        isErrored = true
        this.passwordValidation.minLength = false
      } else {
        this.passwordValidation.minLength = true
      }
      // password has lower letter
      if (!(/[a-z]/.test(this.form.password))) {
        isErrored = true
        this.passwordValidation.lowerLetter = false
      } else {
        this.passwordValidation.lowerLetter = true
      }
      // password has upper letter
      if (!(/[A-Z]/.test(this.form.password))) {
        isErrored = true
        this.passwordValidation.upperLetter = false
      } else {
        this.passwordValidation.upperLetter = true
      }
      // password has digit
      if (!(/[0-9]/.test(this.form.password))) {
        isErrored = true
        this.passwordValidation.digit = false
      } else {
        this.passwordValidation.digit = true
      }

      if (isErrored) {
        this.passwordRepeatError = false
        return false
      }

      return true
    },
    checkPassword () {
      let isErrored = false
      // password is not empty
      if (!this.password) {
        this.hasPasswordError = false
        this.passwordRepeatError = false
        this.passwordValidation.onlyAscii = false
        this.passwordValidation.lowerLetter = false
        this.passwordValidation.upperLetter = false
        this.passwordValidation.digit = false
        this.passwordValidation.minLength = false
        return false
      }
      // password contains only non space ascii symbols
      if (!(/^[!-~]+$/.test(this.form.password))) {
        isErrored = true
        this.passwordValidation.onlyAscii = false
      } else {
        this.passwordValidation.onlyAscii = true
      }
      // password is 6 or more characters
      if (!(/^.{8,}$/.test(this.form.password))) {
        isErrored = true
        this.passwordValidation.minLength = false
      } else {
        this.passwordValidation.minLength = true
      }
      // password has lower letter
      if (!(/[a-z]/.test(this.form.password))) {
        isErrored = true
        this.passwordValidation.lowerLetter = false
      } else {
        this.passwordValidation.lowerLetter = true
      }
      // password has upper letter
      if (!(/[A-Z]/.test(this.form.password))) {
        isErrored = true
        this.passwordValidation.upperLetter = false
      } else {
        this.passwordValidation.upperLetter = true
      }
      // password has digit
      if (!(/[0-9]/.test(this.form.password))) {
        isErrored = true
        this.passwordValidation.digit = false
      } else {
        this.passwordValidation.digit = true
      }

      if (isErrored) {
        this.hasPasswordError = true
        this.passwordRepeatError = false
        return false
      }

      // repeat password is not empty
      if (!this.passwordRepeat) {
        this.passwordRepeatError = false
        this.hasPasswordError = false
        return false
      }
      // passwords are equal
      if (this.form.password !== this.passwordRepeat) {
        this.passwordRepeatError = 'Пароли не совпадают'
        this.hasPasswordError = false
        return false
      }
      this.hasPasswordError = false
      this.passwordRepeatError = false
      return true
    }
  }
}

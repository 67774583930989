<template lang="pug">
  .onboarding-info-modal-component
    Modal(
      :value="show"
      title="С чего начать?"
      width="666px"
      @close="close")
      p 1. Скачайте на смартфон специальное приложение <strong>WhatsApp Business</strong> и создайте аккаунт, который будете использовать
      p 2. <strong>Добавьте номер</strong> в личном кабинете и нажмите <strong>Подключить</strong>
      p 3. Выберите бесплатный <strong>Demo-тариф</strong> для тестирования и отладки
      template(slot="actions")
        Button(@click="close") Добавить номера
</template>

<script>
export default {
  name: 'ModalOnboardingInfo',
  props: {
    show: Boolean
  },
  methods: {
    close () {
      localStorage.setItem('hide-onboarding-modal', 'true')
      this.$emit('update:show', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .onboarding-info-modal-component {
    p {
      margin: 0 0 16px 0;
      font-size: 15px;
      line-height: 24px;

      strong {
        font-weight: 500;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
</style>

<template lang="pug">
  .change-tariff-modal-component
    Modal(
      :value="show"
      title="Выберите тариф"
      width="666px"
      @close="close"
      :loading="isLoading")
      TinkoffWidget(:payment="paymentInfo" ref="widget" )

      .flex.center(v-if="isDataLoading")
        Preloader(static)

      template(v-if="!isDataLoading")
        .row.wrap
          .col.col-6(v-for="(tariff, i) in tariffs" :key="i")
            TariffCard(v-model="selectedTariff" :tariff="tariff")

        span.block.text-center(v-if="!tariffs.length") Нет доступных тарифов

        .tinkoff-info(v-if="showPaymentInfo")
          .icon
            IconLock
          span Вы будете перенаправлены на платёжный шлюз ТинькоффБанка

        template(v-if="tariffs.length" slot="actions")
          .flex
            Button(
              @click="submit"
              size="long"
              :loading="isLoading") Далее
</template>

<script>
import { mapActions } from 'vuex'
import TinkoffWidget from '../TinkoffWidget'
import TariffCard from '@/modules/accounts/components/ModalChangeTariff/TariffCard'

export default {
  name: 'ModalChangeTariff',
  components: { TariffCard, TinkoffWidget },
  props: {
    account: {
      type: Object,
      default: () => ({})
    },
    show: Boolean
  },
  data () {
    return {
      isDataLoading: false,
      isLoading: false,
      selectedTariff: null,
      paymentInfo: {},
      tariffs: []
    }
  },
  watch: {
    show () {
      if (this.show) {
        this.tariffs = []
        this.selectedTariff = null
        this.paymentInfo = {}
        this.loadTariffs()
      }
    }
  },
  computed: {
    showPaymentInfo () {
      const tariff = this.tariffs.find(item => item.id === this.selectedTariff)
      return tariff && tariff.cost > 0
    }
  },
  methods: {
    ...mapActions('api/account', ['getAvailableTariffs', 'activateTariff']),

    async loadTariffs () {
      this.isDataLoading = true

      try {
        const res = await this.getAvailableTariffs()
        if (!res.length) return

        this.tariffs = res.filter(tariff => !!tariff.name)

        const demoTariff = res.find(item => item.name.toLowerCase() === 'demo')
        demoTariff ? this.selectedTariff = demoTariff.id : this.selectedTariff = this.tariffs[0].id

        this.tariffs.sort((a, b) => {
          return a.cost - b.cost
        })
      } catch (err) {
        console.warn('Error', err)
      } finally {
        this.isDataLoading = false
      }
    },
    async submit () {
      this.isLoading = true

      try {
        const data = {
          tariffId: this.selectedTariff
        }
        const id = this.account.id
        this.paymentInfo = await this.activateTariff({ id, data })
        if (!this.paymentInfo.orderId && this.paymentInfo.cost === 0) {
          this.close()
          this.$emit('update')
          this.$notify.add({
            type: 'success',
            content: 'Тариф подключен'
          })
        } else {
          this.$nextTick(() => {
            this.$refs.widget.submit()
          })
        }
      } catch (err) {
        console.warn('Error', err)
        this.$notify.add('Произошла ошибка. Пожалуйста, попробуйте еще раз.')
      } finally {
        this.isLoading = false
      }
    },
    close () {
      this.$emit('update:show', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .change-tariff-modal-component {
    .tariffs {
      //margin-bottom: -16px;
    }

    .tinkoff-info {
      margin: 20px -20px 0 -20px;
      padding: 12px 20px;
      display: flex;
      align-items: center;
      background-color: #EBF9FE;

      .icon {
        height: 18px;
        margin-right: 8px;
        color: #009CE5;
      }

      span {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
</style>

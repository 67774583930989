<template lang="html">
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M15.9765 1.6665C15.3734 1.6665 14.7703 1.89619 14.3098 2.35661L13.3333 3.33317L16.6666 6.6665L17.6432 5.68994C18.5632 4.76994 18.5632 3.27744 17.6432 2.35661C17.1828 1.89619 16.5796 1.6665 15.9765 1.6665ZM12.0833 4.58317L4.16661 12.4998C4.16661 12.4998 5.00411 12.504 5.41661 12.9165C5.82911 13.329 5.82025 14.1535 5.82025 14.1535C5.82025 14.1535 6.66994 14.1698 7.08327 14.5832C7.49661 14.9965 7.49994 15.8332 7.49994 15.8332L15.4166 7.9165L12.0833 4.58317ZM3.05821 14.1665L2.51133 17.0864C2.46717 17.3248 2.67502 17.5326 2.91335 17.4884L5.83327 16.9416L3.05821 14.1665Z"
    fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'IconPencil'
}
</script>

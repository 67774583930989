<template lang="pug">
  .page.balance
    .header.content-section
      .page-header
        h1 История платежей

    .flex.center(v-if="isPageDataLoading")
      Preloader(static)

    template(v-if="!isPageDataLoading")
      Table(
        :headers="headers"
        :items="getComputedTransactions"
        :pagination.sync="pagination"
        @update:pagination="loadTransactions"
        darker)
        template(slot="items")
          tr(v-for="(item, i) in getComputedTransactions" :key="i")
            td {{ getDate(item.modification_datetime) }}
            td(:class="`${item.amount > 0 ? 'account-refill' : ''}`") {{ getAmount(item.amount) }}
            td {{ getStatus(item.status) }}
            td {{ getTariff(item.tariffId) }}
            td {{ getMsisdn(item.accountId) }}

    ModalPayment(:show.sync="showPaymentModal")
</template>

<script>
import { mapActions } from 'vuex'
import { paymentStatuses } from '../utils/constants'
import moment from 'moment'
import ModalPayment from '../components/ModalPayment'

export default {
  name: 'Transactions',
  components: { ModalPayment },
  data () {
    return {
      isPageDataLoading: true,
      showPaymentModal: false,
      paymentStatuses,
      headers: [
        { caption: 'Дата платежа' },
        { caption: 'Сумма, ₽' },
        { caption: 'Статус' },
        { caption: 'Тариф' },
        { caption: 'Номер телефона' }
      ],
      items: [],
      accounts: [],
      tariffs: [],
      pagination: {
        page: 1,
        perPage: 10,
        total: 0
      }
    }
  },
  computed: {
    getComputedTransactions () {
      return this.items.filter(item => item.amount !== null && item.status !== null)
    }
  },
  methods: {
    ...mapActions('api/transactions', ['getTransactions']),
    ...mapActions('api/account', ['getAccounts', 'getAvailableTariffs']),

    getAmount (amount) {
      return amount > 0 ? amount / 100 : 0
    },
    getDate (date) {
      return moment(date).format('DD.MM.YYYY HH:mm')
    },
    getTariff (tariffId) {
      const currentTariff = this.tariffs.find(tariff => tariff.id === tariffId)
      return currentTariff ? currentTariff.name : ''
    },
    getMsisdn (accountId) {
      const currentAccount = this.accounts.find(account => account.id === accountId)
      return currentAccount ? currentAccount.senderName : ''
    },
    getStatus (status) {
      if (!status) return ''
      const currentStatus = this.paymentStatuses.find(item => item.value === status.toUpperCase())
      return currentStatus ? currentStatus.key : ''
    },
    async loadTransactions () {
      const params = {
        page: this.pagination.page,
        per_page: this.pagination.perPage
      }

      try {
        const { items, total } = await this.getTransactions({ params })
        this.items = items
        this.pagination.total = total
      } catch (err) {
        console.warn('Error', err)
        this.$notify.add('Произошла ошибка. Пожалуйста, попробуйте еще раз.')
      } finally {
        this.isPageDataLoading = false
      }
    },
    async loadTariffs () {
      try {
        this.tariffs = await this.getAvailableTariffs()
      } catch (err) {
        console.warn('Error', err)
      }
    },
    async loadAccounts () {
      const params = {
        per_page: 999
      }

      try {
        const { items } = await this.getAccounts({ params })
        this.accounts = items
      } catch (err) {
        console.warn('Error', err)
      }
    }
  },
  created () {
    this.loadTariffs()
    this.loadAccounts()
    this.loadTransactions()
  }
}
</script>

<style lang="scss" scoped>
  .page.balance {
    .header {
      margin: 16px 0;
    }

    .button-group {
      margin-bottom: 16px;
    }

    .account-refill {
      color: #42C58D;
    }
  }
</style>

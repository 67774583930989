<template lang="pug">
  .auth-header-component
    img(src="/img/logo.svg")
</template>

<script>
export default {
  name: 'HeaderAuth'
}
</script>

<style lang="scss" scoped>
  .auth-header-component {
    display: flex;
    justify-content: center;
    padding: 30px 0;

    img {
      width: 200px;
    }
  }
</style>

import { version } from '../../package.json'

export const appVersion = version

export const defaultTitle = 'Weasy'

export const englishAlphabet = [
  'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h',
  'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p',
  'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']

export const russianAlphabet = [
  'а', 'б', 'в', 'г', 'д', 'е', 'ё', 'ж', 'з', 'и',
  'й', 'к', 'л', 'м', 'н', 'о', 'п', 'р', 'с', 'т',
  'у', 'ф', 'х', 'ц', 'ч', 'ш', 'щ', 'ъ', 'ы', 'ь',
  'э', 'ю', 'я'
]

export const avatarColors = [
  '#fba76f',
  '#f88fb0',
  '#73cdd0',
  '#6fb1e4',
  '#ecd074',
  '#cc90e2',
  '#e57979'
]

export const connectionTypes = [
  { key: 'SMSС', value: 'SMSC' },
  { key: 'UniversalSMSC', value: 'UniversalSMSC' },
  { key: 'Comex', value: 'Comex' },
  { key: 'Comex-open', value: 'Comex-open' },
  { key: 'FTP', value: 'FTP' },
  { key: 'Direct', value: 'Direct' },
  { key: 'Digital Direct', value: 'Digital Direct' }
]

export const DATE_UNFORMATTED = 'YYYY-MM-DD'
export const DATETIME_UNFORMATTED = 'YYYY-MM-DD HH:mm:ss'
export const DATE_FORMATTED = 'DD.MM.YYYY'
export const DATETIME_FORMATTED = 'DD.MM.YYYY HH:mm'

const hostname = window.location.hostname
export const tinkoffWidgetTerminalKey = hostname === 'lk.weasy.pro' ? '1653472318723' : '1653472318723DEMO'

export default {
  defaultTitle,
  avatarColors,
  tinkoffWidgetTerminalKey,
  appVersion,
  DATE_UNFORMATTED,
  DATETIME_UNFORMATTED,
  DATE_FORMATTED,
  DATETIME_FORMATTED
}

<template lang="html">
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.306 10.9508C16.0089 10.7329 15.8314 10.3829 15.8314 10.0142C15.8314 9.64541 16.0089 9.29541 16.3056 9.07791L17.6781 8.07166C17.8968 7.91083 17.9873 7.62791 17.9018 7.36999C17.5485 6.30833 16.9898 5.33999 16.2406 4.49124C16.0602 4.28791 15.7693 4.22374 15.5206 4.33291L13.9752 5.01249C13.6385 5.16083 13.2464 5.13958 12.9268 4.95499C12.6077 4.77083 12.3935 4.44249 12.3531 4.07624L12.1673 2.37958C12.1377 2.10958 11.9368 1.88958 11.671 1.83499C10.5889 1.61374 9.45184 1.60999 8.35059 1.83083C8.08309 1.88458 7.88184 2.10458 7.85225 2.37583L7.66809 4.06166C7.62767 4.42833 7.4135 4.75666 7.0935 4.94083C6.77434 5.12499 6.3835 5.14708 6.04559 4.99833L4.49184 4.31499C4.24517 4.20583 3.9535 4.26916 3.77309 4.47208C3.02142 5.31749 2.46017 6.28458 2.10309 7.34541C2.01642 7.60291 2.10642 7.88749 2.326 8.04874L3.68975 9.04874C3.98725 9.26708 4.16475 9.61707 4.16475 9.98583C4.16475 10.3546 3.98725 10.7046 3.69059 10.9221L2.31809 11.9283C2.09934 12.0892 2.00892 12.3721 2.09434 12.63C2.44767 13.6917 3.00642 14.66 3.75559 15.5087C3.936 15.7125 4.22767 15.7771 4.47559 15.6671L6.021 14.9875C6.35767 14.8392 6.74934 14.8604 7.06934 15.045C7.3885 15.2292 7.60267 15.5575 7.64309 15.9237L7.82892 17.6204C7.8585 17.8904 8.05934 18.1104 8.32517 18.165C8.87267 18.2767 9.43517 18.3333 9.99809 18.3333C10.5473 18.3333 11.1014 18.2779 11.6452 18.1687C11.9127 18.115 12.1139 17.895 12.1435 17.6237L12.3281 15.9379C12.3685 15.5712 12.5827 15.2429 12.9027 15.0587C13.2218 14.875 13.6131 14.8533 13.9506 15.0012L15.5043 15.6846C15.7518 15.7942 16.0427 15.7308 16.2231 15.5275C16.9748 14.6821 17.536 13.715 17.8931 12.6542C17.9798 12.3967 17.8898 12.1121 17.6702 11.9508L16.306 10.9508ZM9.99809 12.9167C8.38725 12.9167 7.08142 11.6108 7.08142 9.99999C7.08142 8.38916 8.38725 7.08333 9.99809 7.08333C11.6089 7.08333 12.9148 8.38916 12.9148 9.99999C12.9148 11.6108 11.6089 12.9167 9.99809 12.9167Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconGear'
}
</script>

<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="fill" d="M9.42859 4H8.85716V2.85714C8.85716 1.28 7.57716 0 6.00002 0C4.42287 0 3.14287 1.28 3.14287 2.85714V4H2.57145C1.94002 4 1.42859 4.51143 1.42859 5.14286V10.8571C1.42859 11.4886 1.94002 12 2.57145 12H9.42859C10.06 12 10.5714 11.4886 10.5714 10.8571V5.14286C10.5714 4.51143 10.06 4 9.42859 4ZM6.00002 9.14286C5.36859 9.14286 4.85716 8.63143 4.85716 8C4.85716 7.36857 5.36859 6.85714 6.00002 6.85714C6.63145 6.85714 7.14287 7.36857 7.14287 8C7.14287 8.63143 6.63145 9.14286 6.00002 9.14286ZM7.77145 4H4.22859V2.85714C4.22859 1.88 5.02287 1.08571 6.00002 1.08571C6.97716 1.08571 7.77145 1.88 7.77145 2.85714V4Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconLock'
}
</script>

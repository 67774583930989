<template lang="pug">
  .loader-component(:class="{ static }")
    div
</template>

<script>
export default {
  name: 'Loader',
  props: {
    static: Boolean
  }
}
</script>

<style lang="scss" scoped>
  .loader-component {
    &:not(.static) {
      position: fixed;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $color-white;
      z-index: 100;
    }

    &.static {
      position: static;
      width: 56px;
      height: 56px;

      div {
        margin: 0;
        flex-shrink: 0;
      }
    }

    div {
      display: inline-block;
      width: 50px;
      height: 50px;
      margin: 2rem;
      border-radius: 50%;
      border: 0.3rem solid #34A853;
      border-top-color: rgba(#34A853, 0.2);
      animation: 1s spin infinite ease;

      @keyframes spin {
        from {
          transform: rotate(0);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
  }
</style>

<template lang="pug">
  .support-info-modal
    Modal(
      :value="show"
      title="Техническая подджерка"
      width="446px"
      :closable="false"
      :outside-close="true"
      @close="close")
      span
        b Email для обращений:&nbsp;
          a(href="mailto:support@weasy.pro") support@weasy.pro
      span При получения статуса «Ошибка оплаты» укажите ваш логин в системе, номер телефона, для которого приобретался тариф, дату и время операции.
</template>

<script>
export default {
  name: 'ModalSupportInfo',
  props: {
    show: Boolean
  },
  methods: {
    close () {
      this.$emit('update:show', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .support-info-modal {
    span {
      display: block;
      font-size: 15px;
      line-height: 22px;

      b {
        font-weight: 500;
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
</style>

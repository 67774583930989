import api from '@/api'

export const actions = {
  // GET
  getAccounts (_, params = {}) {
    return api.get('/account/', params)
  },
  getAccountById (_, id) {
    return api.get(`/account/${id}`)
  },
  getAvailableMsisdns (_, params = {}) {
    return api.get('/account/available-msisdns/', params)
  },
  getAccountQrCode (_, id) {
    return api.get(`/account/${id}/get-qr-code`)
  },
  getAvailableTariffs () {
    return api.get('/account/available-tariffs')
  },
  restoreWhatsAppAccount (_, id) {
    return api.get(`/account/${id}/restore-account`)
  },

  // POST
  createAccount (_, data = {}) {
    return api.post('/account', data)
  },
  setCallback (_, data = {}) {
    return api.post('/account/set-callback', data)
  },
  activateTariff (_, { id, data }) {
    return api.post(`/account/${id}/activate-tariff`, { data })
  },

  // PUT
  updateAccount (_, { id, data = {} }) {
    return api.put(`/account/${id}`, { data })
  },

  // DELETE
  deleteAccount (_, id) {
    return api.delete(`/account/${id}`)
  }
}

export default {
  actions,
  namespaced: true
}

<template lang="html">
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_22900_61275)">
    <path
        d="M21 0H3C1.34315 0 0 1.34315 0 3V21C0 22.6569 1.34315 24 3 24H21C22.6569 24 24 22.6569 24 21V3C24 1.34315 22.6569 0 21 0Z"
        fill="#F2F2F2"/>
    <path
        d="M12 15.5C12.825 15.5 13.5 16.175 13.5 17C13.5 17.825 12.825 18.5 12 18.5C11.175 18.5 10.5 17.825 10.5 17C10.5 16.175 11.175 15.5 12 15.5ZM12 13.5C11.175 13.5 10.5 12.825 10.5 12C10.5 11.175 11.175 10.5 12 10.5C12.825 10.5 13.5 11.175 13.5 12C13.5 12.825 12.825 13.5 12 13.5ZM12 8.5C11.175 8.5 10.5 7.825 10.5 7C10.5 6.175 11.175 5.5 12 5.5C12.825 5.5 13.5 6.175 13.5 7C13.5 7.825 12.825 8.5 12 8.5Z"
        fill="#818B90"/>
    </g>
    <defs>
    <clipPath id="clip0_22900_61275">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconSettingsAndroid'
}
</script>

<template lang="pug">
  .balance-info-component
    span.balance 1000 ₽
    a.link Пополнить
</template>

<script>
export default {
  name: 'BalanceInfo'
}
</script>

<style lang="scss" scoped>
  .balance-info-component {
    .balance {
      font-size: 14px;
      display: inline-block;
      margin-right: 6px;
    }
  }
</style>

import Integrations from './views/Integrations'

export default [
  {
    name: 'integrations',
    path: '/integrations',
    component: Integrations,
    meta: {
      title: 'Интеграции с Aimylogic',
      auth: true
    }
  }
]

<template lang="pug">
  .info-message-component
    slot
</template>

<script>
export default {
  name: 'InfoMessage'
}
</script>

<style lang="scss" scoped>
  .info-message-component {
    background-color: #FCF4DA;
    box-shadow: 0 0 0 1px #F6E0AD inset;
    border-radius: 4px;
    padding: 12px 16px;

    ::v-deep {
      span {
        font-size: 14px;
        line-height: 22px;
      }

      a {
        font-size: 14px;
        line-height: 1;
        display: inline-block;
        color: #009CE5;
        border-bottom: 1px solid rgba(#009CE5, 0.5);
        transition: color 0.2s ease, border-color 0.2s ease;

        &:hover {
          color: $color-red-100;
          border-color: rgba($color-red-100, 0.5);
          transition: none;
        }
      }
    }
  }
</style>

import api from '@/api'

export const actions = {
  //GET
  getClient (_, params = {}) {
    return api.get('/client/', params)
  },

  // POST
  updateClient (_, data = {}) {
    return api.post('/client/edit', data)
  }
}

export default {
  actions,
  namespaced: true
}

<template lang="pug">
  .page.accounts
    .header.content-section
      .page-header
        h1 Аккаунты в WhatsApp

    .flex.center(v-if="isPageDataLoading")
      Preloader(static)

    template(v-if="!isPageDataLoading")
      .button-group
        Button(
          @click="showAddModal = true"
          color="success"
          size="m"
          squared)
            IconPlus

      NotFound(v-if="!getComputedAccounts.length") Нет данных для отображения

      .accounts(v-if="getComputedAccounts.length")
        .preloader(v-if="isLoading")
          Preloader(static)
        .row.wrap
          .col.col-4(v-for="(item, i) in getComputedAccounts" :key="i")
            .account
              AccountCard(
                :account="item"
                @openPayModal="openPayModal"
                @openAuthModal="openAuthModal"
                @openEditModal="openEditModal"
                @openDeleteModal="openDeleteModal"
                @restoreAccount="restoreAccount"
                @openSupportModal="openSupportModal")

      Pagination(
        v-if="getComputedAccounts.length && pagination.total > 10"
        v-model="pagination"
        :per-page-options="[12, 60, 120]")

    ModalAddAccount(:show.sync="showAddModal" @update="loadAccounts")

    ModalDeleteAccount(
      :show.sync="showDeleteModal"
      :item="currentDeleteItem"
      @update="loadAccounts")

    ModalEditAccount(
      :show.sync="showEditModal"
      :item="currentEditItem"
      @update="loadAccounts")

    ModalChangeTariff(
      :show.sync="showChangeTariffModal"
      :account="currentPayAccount"
      @update="loadAccounts")

    ModalApiAuth(
      :show.sync="showAuthModal"
      :item="currentAuthItem"
      @close="loadAccounts"
      @updateAccounts="loadAccounts")

    ModalOnboardingInfo(:show.sync="showOnboardingModal")

    ModalSupportInfo(:show.sync="showSupportModal")

</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import { DATETIME_FORMATTED } from '@/utils/constants'
import mixinGetStatusParams from '../mixins/get-status-params'
import mixinIsAccountBlocked from '../mixins/is-account-blocked'
import ModalAddAccount from '../components/ModalAddAccount'
import ModalEditAccount from '../components/ModalEditAccount'
import ModalApiAuth from '../components/ModalApiAuth'
import ModalSupportInfo from '../components/ModalSupportInfo'
import ModalChangeTariff from '../components/ModalChangeTariff/ModalChangeTariff'
import ModalDeleteAccount from '../components/ModalDeleteAccount'
import InputPreloader from '@/components/form/select/input-preloader'
import IconPlus from '../components/icons/IconPlus'
import AccountCard from '@/modules/accounts/components/AccountCard'
import NotFound from '@/components/not-found'
import Pagination from '@/components/pagination'
import ModalOnboardingInfo from '@/modules/accounts/components/ModalOnboardingInfo'

export default {
  name: 'Accounts',
  mixins: [mixinIsAccountBlocked, mixinGetStatusParams],
  components: {
    ModalOnboardingInfo,
    Pagination,
    NotFound,
    AccountCard,
    InputPreloader,
    ModalDeleteAccount,
    ModalChangeTariff,
    ModalSupportInfo,
    ModalApiAuth,
    ModalEditAccount,
    ModalAddAccount,
    IconPlus
  },
  data () {
    return {
      isPageDataLoading: true,
      isLoading: false,
      showAddModal: false,
      showEditModal: false,
      showDeleteModal: false,
      showAuthModal: false,
      showSupportModal: false,
      showChangeTariffModal: false,
      showOnboardingModal: false,
      currentPayAccount: {},
      headers: [
        { caption: 'Телефон' },
        { caption: 'Название' },
        { caption: 'Статус' },
        { caption: 'Оплачено до' },
        { caption: '' }
      ],
      items: [],
      currentEditItem: {},
      currentDeleteItem: {},
      currentAuthItem: {},
      pagination: {
        page: 1,
        perPage: 12,
        total: 0
      }
    }
  },
  watch: {
    pagination () {
      this.loadAccounts()
    }
  },
  computed: {
    getComputedAccounts () {
      // Порядок отображения статусов в таблице
      // ACTIVE (sorted)
      // NOT_AUTHORIZED (sorted)
      // STARTING
      // PAYMENT_WAIT
      // CREATED
      // ERROR
      // LOADING
      // NOT_ACTIVE
      // SLEEP_MODE
      // DELETED - Не отображается

      const getStatusItems = (status) => {
        return this.items.filter(item => this.getStatusParams(item)?.value === status && !this.isAccountBlocked(item))
      }

      const getBlockedItems = () => {
        return this.items.filter(item => {
          return this.isAccountBlocked(item)
        })
      }


      // Добавляем айтемы в статусе ACTIVE перед этим отсортировав по дате по возрастанию
      const activeItems = getStatusItems('AUTHORIZED').sort((a, b) => {
        return new Date(b.paidTill) - new Date(a.paidTill)
      })

      // Добавляем айтемы в статусе NOT_AUTHORIZED перед этим отсортировав по дате по возрастанию
      const notAuthorizedItems = getStatusItems('NOT_AUTHORIZED').sort((a, b) => {
        return new Date(b.paidTill) - new Date(a.paidTill)
      })

      const yellowCardItems = getStatusItems('YELLOW_CARD')
      const startingItems = getStatusItems('STARTING')
      const paymentWaitItems = getStatusItems('PAYMENT_WAIT')
      const notActiveItems = getStatusItems('NOT_ACTIVE')
      const sleepModeItems = getStatusItems('SLEEP_MODE')
      const errorItems = getStatusItems('ERROR')
      const loadingItems = getStatusItems('LOADING')
      const blockedItems = getBlockedItems()

      console.log(this.items)

      const items = [
        ...activeItems,
        ...notAuthorizedItems,
        ...startingItems,
        ...paymentWaitItems,
        ...yellowCardItems,
        // ...createdItems,
        ...notActiveItems,
        ...sleepModeItems,
        ...errorItems,
        ...blockedItems,
        ...loadingItems
      ]

      return items
    }
  },
  methods: {
    ...mapActions('api/account', ['getAccounts', 'restoreWhatsAppAccount']),

    getPaidTill (paidTill) {
      if (!paidTill) return ''
      return moment(paidTill).format(DATETIME_FORMATTED)
    },
    openSupportModal () {
      this.showSupportModal = true
    },
    openPayModal (item) {
      this.currentPayAccount = JSON.parse(JSON.stringify(item))
      this.showChangeTariffModal = true
    },
    openAuthModal (item) {
      this.currentAuthItem = JSON.parse(JSON.stringify(item))
      this.showAuthModal = true
    },
    openEditModal (item) {
      this.currentEditItem = JSON.parse(JSON.stringify(item))
      this.showEditModal = true
    },
    openDeleteModal (item) {
      this.currentDeleteItem = JSON.parse(JSON.stringify(item))
      this.showDeleteModal = true
    },
    async loadAccounts () {
      this.isLoading = true

      const params = {
        page: this.pagination.page,
        per_page: this.pagination.perPage
      }

      try {
        const { items, total } = await this.getAccounts({ params })
        this.items = items
        this.pagination.total = total
      } catch (err) {
        console.warn('Error', err)
      } finally {
        this.isLoading = false
        this.isPageDataLoading = false
      }
    },
    async restoreAccount (item) {
      this.isLoading = true

      const id = item.id

      try {
        await this.restoreWhatsAppAccount(id)
        this.$notify.add({
          content: 'Изменения сохранены',
          type: 'success'
        })
        this.loadAccounts()
      } catch (err) {
        this.$notify.add('Произошла ошибка. Пожалуйста, попробуйте еще раз.')
        console.warn('Error', err)
      } finally {
        this.isLoading = false
      }
    }
  },
  created () {
    this.loadAccounts()
  },
  mounted () {
    if (!localStorage.getItem('hide-onboarding-modal')) {
      this.showOnboardingModal = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .page.accounts {
    .header {
      margin: 16px 0;
    }

    .button-edit,
    .button-delete {
      width: 20px;
      height: 20px;
      color: $color-gray-60;
      transition: color 0.1s ease;

      &:hover {
        color: #33B0EA;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .button-group {
      margin-bottom: 16px;
    }

    .accounts {
      position: relative;
      margin-bottom: 4px;

      .preloader {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: calc(100% - 16px);
        background-color: rgba(#F7F9FA, 0.5);
        z-index: 2;
      }

      .account {
        height: calc(100% - 16px);
        margin-bottom: 16px;
      }
    }

    ::v-deep {
      .table-component .table-wrapper {
        .table {
          tr {
            td:last-child {
              text-align: right;
              vertical-align: bottom;
            }
          }
        }
      }
    }
  }
</style>

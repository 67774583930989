<template lang="pug">
  .page.login
    transition(name="fadeUp" mode="out-in")
      LoginForm(
        v-if="showLoginForm"
        @openForgotForm="showLoginForm = false"
        :key="0")
      ForgotPasswordForm(
        v-if="!showLoginForm"
        @close="showLoginForm = true"
        :key="1")
</template>

<script>
import LoginForm from '../components/LoginForm'
import ForgotPasswordForm from '../components/ForgotPasswordForm'

export default {
  name: 'Login',
  components: {
    ForgotPasswordForm,
    LoginForm
  },
  data () {
    return {
      showLoginForm: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .page.login {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    min-height: calc(100vh - 131px);
  }
</style>

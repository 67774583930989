<template lang="pug">
  .tinkoff-widget-component
    script(type="application/javascript" :src="paymentScriptUrl")
    form(name="TinkoffPayForm" onsubmit="pay(this); return false;")
      input.tinkoffPayRow(
        type="hidden"
        name="terminalkey"
        :value="terminalKey")
      input.tinkoffPayRow(
        type="hidden"
        name="frame"
        value="false")
      input.tinkoffPayRow(
        type="hidden"
        name="language"
        value="ru")
      input.tinkoffPayRow(
        type="hidden"
        name="amount"
        :value="payment.cost")
      input.tinkoffPayRow(
        type="hidden"
        name="order"
        :value="payment.orderId")
      input.tinkoffPayRow(
        type="hidden"
        name="description"
        value="Абонентская плата за использование сервиса Weasy")
      input.tinkoffPayRow(
        type="hidden"
        name="receipt"
        :value="getReceiptParams")
      input.tinkoffPayRow(
        type="submit"
        value="Оплатить"
        ref="submit"
        hidden)
</template>

<script>
import { mapState } from 'vuex'
import { tinkoffWidgetTerminalKey as terminalKey } from '@/utils/constants'

export default {
  name: 'TinkoffWidget',
  props: {
    payment: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      terminalKey,
      paymentScriptUrl: 'https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js'
    }
  },
  computed: {
    ...mapState({
      user: state => state.api.auth.user
    }),

    getReceiptParams () {
      const params = {
        Email: this.user.email,
        EmailCompany: 'weasy@weasy.pro',
        Taxation: 'usn_income',
        Items: [
          {
            Name: 'Абонентская плата за использование сервиса Weasy',
            Price: this.payment.cost + '00',
            Amount: this.payment.cost + '00',
            Quantity: 1.00,
            PaymentObject: 'service',
            Tax: 'none'
          }
        ]
      }
      return JSON.stringify(params)
    },
    getAmount () {
      if (!this.payment.cost) return 0.00
      return this.payment.cost + '.00'
    }
  },
  methods: {
    submit () {
      this.$refs.submit.click()
    }
  }
}
</script>

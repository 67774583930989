<template lang="pug">
  .payment-modal-component
    Modal(
      :value="show"
      title="Пополнить баланс"
      width="666px"
      @close="close"
      :loading="isLoading")
      template
        .fields
          .field
            span.label Сумма, ₽
            Input(number)
        .tinkoff-info
          .icon
            IconLock
          span Для оплаты вы будете перенаправлены на платёжный шлюз ТинькофБанк

      //template
        .redirect-info
          span Перенаправлявляем вас на платежный шлюз ТинькофБанк...
          span Если этого не произошло нажмите&nbsp;
            a(href="/") сюда

      template(slot="actions")
        .flex
          Button(
            @click="submit"
            size="long"
            :loading="isLoading") Пополнить
          Button(
            @click="close"
            size="long"
            color="secondary"
            :disabled="isLoading") Отмена
</template>

<script>
export default {
  name: 'ModalPayment',
  props: {
    show: Boolean
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    submit () {
      //
    },
    close () {
      this.$emit('update:show', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .payment-modal-component {
    .fields {
      .field {
        margin-bottom: 20px;
        max-width: 300px;
      }
    }

    .redirect-info {
      span {
        display: block;
        font-size: 15px;
        line-height: 22px;
        &:first-child {
          margin-bottom: 16px;
        }
      }
    }

    .tinkoff-info {
      margin: 0 -20px;
      padding: 12px 20px;
      display: flex;
      align-items: center;
      background-color: #EBF9FE;

      .icon {
        height: 18px;
        margin-right: 8px;
        color: #009CE5;
      }

      span {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
</style>

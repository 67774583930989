<template lang="pug">
  .textarea-component(:class="classList" ref="container")
    textarea(
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
      @change="onChange"
      :value="value"
      :disabled="disabled || loading"
      :maxlength="maxlength"
      :autofocus="autofocus"
      :readonly="readonly"
      :placeholder="placeholder"
      :rows="rows"
      ref="textarea")
    .textarea-count(v-if="showCounter")
      span {{ getCountMessage }}
</template>

<script>
export default {
  name: 'Textarea',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      required: false
    },
    resize: {
      type: String,
      default: 'none',
      validator (value) {
        const isValid = /none|both|horizontal|vertical|inherit/gi.test(value)
        if (!isValid) {
          console.warn('[Textarea component]: Allowable values: none|both|horizontal|vertical|inherit')
        }
        return isValid
      }
    },
    rows: {
      type: [String, Number],
      default: null,
      validator (value) {
        const isValid = /[0-9]/g.test(value)
        if (!isValid) {
          console.warn('[Textarea component]: You can use only numbers/string numbers')
        }
        return isValid
      }
    },
    maxlength: {
      type: [String, Number],
      default: null,
      validator (value) {
        const isValid = /[0-9]/g.test(value)
        if (!isValid) {
          console.warn('[Textarea component]: You can use only numbers/string numbers')
        }
        return isValid
      }
    },
    countMessage: {
      type: String,
      default: 'Осталось % символов',
      validator (value) {
        const isValid = /%/g.test(value)
        if (!isValid) {
          console.warn('[Textarea component]: There is no "%" symbol in a countMessage prop')
        }
        return isValid
      }
    },
    autofocus: Boolean,
    showCounter: Boolean,
    autoHeight: Boolean,
    disabled: Boolean,
    loading: Boolean,
    error: Boolean,
    phone: Boolean,
    readonly: Boolean
  },
  data () {
    return {
      isFocused: false,
      resizeObserver: null
    }
  },
  watch: {
    resize () {
      this.setObserver()
    }
  },
  computed: {
    getCountMessage () {
      const count = this.maxlength - this.value.length
      return this.countMessage.replace('%', count)
    },
    classList () {
      let resize = this.resize.toLowerCase()
      resize = resize.match(/none|both|horizontal|vertical|inherit/gi) || []

      if (resize) {
        resize = `resize-${resize[0]}`
      }

      return {
        [resize]: resize,
        disabled: this.disabled,
        loading: this.loading,
        readonly: this.readonly,
        error: this.error,
        focused: this.isFocused
      }
    }
  },
  methods: {
    onInput (e) {
      this.$emit('input', e.target.value)
      if (this.autoHeight) {
        this.adjustHeight()
      }
    },
    onFocus (e) {
      this.isFocused = true
      this.$emit('focus', e.target.value)
    },
    onBlur (e) {
      this.isFocused = false
      this.$emit('blur', e.target.value)
    },
    onChange (e) {
      this.$emit('change', e.target.value)
    },
    adjustHeight () {
      const textarea = this.$refs.textarea
      textarea.style.overflow = 'hidden'
      textarea.style.height = 'auto'
      textarea.style.height = `${textarea.scrollHeight}px`
    },
    updateContainerSize () {
      const container = this.$refs.container
      const textarea = this.$refs.textarea
      container.style.width = textarea.getBoundingClientRect().width + 'px'
    },
    setObserver () {
      if (this.resize === 'both' || this.resize === 'horizontal') {
        this.resizeObserver = new ResizeObserver(this.updateContainerSize)
        this.resizeObserver.observe(this.$refs.textarea)
      } else if (this.resizeObserver) {
        this.unsetObserver()
      }
    },
    unsetObserver () {
      if (this.resizeObserver) {
        this.resizeObserver.unobserve(this.$refs.textarea)
      }
    }
  },
  mounted () {
    this.setObserver()
  },
  beforeDestroy () {
    this.unsetObserver()
  }
}
</script>

<style lang="scss" scoped>
  .textarea-component {
    display: block;
    background-color: $color-white;
    box-shadow: 0 0 0 1px #CCD7E0 inset;
    border-radius: 4px;
    transition: box-shadow 0.2s ease, background-color 0.2s ease;

    &.resize {
      &-none textarea {
        resize: none;
      }
      &-both textarea {
        resize: both;
      }
      &-horizontal textarea {
        resize: horizontal;
      }
      &-vertical textarea {
        resize: vertical;
      }
      &-inherit textarea {
        resize: inherit;
      }
    }

    &.disabled {
      background-color: #F1F4F5;
    }

    &.focused {
      box-shadow: 0 0 0 2px $color-blue-80 inset;

      .textarea-count {
        box-shadow:
          0 1px 0 0 #CCD7E0 inset,
          2px 0 0 0 $color-blue-80 inset,
          -2px -2px 0 0 $color-blue-80 inset;
      }

      &.error {
        box-shadow: 0 0 0 2px #FF766D inset;
      }
    }

    &.error {
      box-shadow: 0 0 0 2px #FF766D inset;
    }

    textarea {
      display: block;
      width: 100%;
      padding: 8px 12px;
      background-color: transparent;
      border: 0;
      font-size: 15px;
      line-height: 22px;
      outline: none;

      &::placeholder {
        font-size: 15px;
        line-height: 22px;
        color: $color-gray-80;
      }

      @include custom-scrollbar;
    }

    .textarea-count {
      background-color: #F7F8F9;
      box-shadow: 0 0 0 1px #CCD7E0 inset;
      padding: 9px 12px;
      border-radius: 0 0 4px 4px;
      transition: box-shadow 0.2s ease;

      span {
        font-size: 12px;
        line-height: 18px;
        color: $color-gray-100;
      }
    }
  }
</style>

<template lang="pug">
  .edit-account-modal-component
    Modal(
      :value="show"
      title="Редактировать номер"
      width="666px"
      @close="close"
      :loading="isLoading")
      ValidationObserver(ref="validation" tag="div")
        .fields
          .field
            Input(v-model="form.senderName" disabled)
            span.info-message С этого номера будут отправляться сообщения вашим клиентам в WhatsApp
          .field
            Input(v-model="form.name" maxlength="100")
            span.info-message Это видите только вы

      template(slot="actions")
        .flex
          Button(
            @click="submit"
            :loading="isLoading"
            :disabled="!form.senderName.length"
            size="long") Сохранить
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ModalEditAccount',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    show: Boolean
  },
  data () {
    return {
      isLoading: false,
      form: {
        senderName: '',
        name: ''
      }
    }
  },
  watch: {
    show () {
      if (this.show) {
        this.form.senderName = this.item.senderName
        this.form.name = this.item.name
      }
    }
  },
  methods: {
    ...mapActions('api/account', ['updateAccount']),

    async submit () {
      this.isLoading = true

      const data = {
        name: this.form.name
      }

      const id = this.item.id

      try {
        await this.updateAccount({ id, data })
        this.$notify.add({
          content: 'Изменения сохранены',
          type: 'success'
        })
        this.close()
        this.$emit('update')
      } catch (err) {
        console.warn('Error', err)
        this.$notify.add('Произошла ошибка. Пожалуйста, попробуйте еще раз.')
      } finally {
        this.isLoading = false
      }
    },
    close () {
      this.$emit('update:show', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .edit-account-modal-component {
    .fields {
      display: flex;

      .field {
        flex: 1;

        & + .field {
          margin-left: 20px;
        }
      }
    }

    ::v-deep {
      .modal-component .modal-container {
        .modal-content {
          padding-bottom: 16px;
        }
      }
    }
  }
</style>

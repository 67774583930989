<template lang="pug">
  .dropdown-component(v-outside="close" :class="{ disabled }")
    button.custom-dropdown-button(
      v-if="$slots.button"
      @click="toggleOptions"
      ref="button")
      slot(name="button")

    button.button(
      v-if="!$slots.button"
      @click="toggleOptions"
      ref="button"
      :class="{'hovered': show}")
      IconDots

    transition(name="fadeUp" :duration="60")
      template(v-if="show && !disabled")
        .options(@click="show = false" ref="options")
          slot
</template>

<script>
import IconDots from './IconDots'

export default {
  name: 'Dropdown',
  components: {
    IconDots
  },
  props: {
    verticalAlign: {
      type: String,
      default: 'left'
    },
    disabled: Boolean
  },
  data () {
    return {
      show: false
    }
  },
  methods: {
    toggleOptions () {
      if (this.disabled) return
      if (this.show) {
        this.show = false
      } else {
        this.show = true
        this.$nextTick(() => {
          this.setWindowCoords()
        })
      }
    },
    close () {
      this.show = false
    },
    setWindowCoords () {
      if (this.show && !this.disabled) {
        const button = this.$refs.button
        const buttonCoords = button.getBoundingClientRect()
        const options = this.$refs.options
        const optionsCoords = options.getBoundingClientRect()

        // Позиционирование по горизонтали
        switch (this.verticalAlign) {
        case 'left':
          options.style.left =  `${buttonCoords.left - optionsCoords.width + buttonCoords.width / 2}px`
          break
        case 'right':
          options.style.left =  `${buttonCoords.left}px`
          break
        default:
          options.style.left =  `${buttonCoords.left - optionsCoords.width + buttonCoords.width / 2}px`
          break
        }

        // Позиционирование по вертикали
        const isItemFits = buttonCoords.top + buttonCoords.height + optionsCoords.height < window.innerHeight
        if (!isItemFits) {
          if (this.$slots.button) {
            options.style.top = `${buttonCoords.bottom - buttonCoords.height - optionsCoords.height - 3}px`
          } else {
            options.style.top = `${window.innerHeight - optionsCoords.height - 10}px`
          }
        } else {
          if (this.$slots.button) {
            options.style.top = `${buttonCoords.bottom + 3}px`
          } else {
            options.style.top = `${buttonCoords.bottom - buttonCoords.height / 2}px`
          }
        }

        // Скрытие окна, если оно не в viewport
        if (optionsCoords.bottom < 0 || buttonCoords.top + buttonCoords.height > window.innerHeight) {
          this.show = false
        }
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', this.setWindowCoords)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.setWindowCoords)
  }
}
</script>

<style lang="scss" scoped>
  .dropdown-component {
    user-select: none;
    display: inline-block;

    &.disabled {
      .button {
        cursor: default;

        &:hover {
          background-color: transparent;
        }
      }
    }

    .custom-dropdown-button {
      cursor: pointer;
      line-height: 17px;
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      text-align: center;
      background-color: transparent;
      border: 0;
      border-radius: 50%;
      color: #CBD3D9;
      cursor: pointer;
      transition: background-color 0.3s ease, color 0.3s ease;
      will-change: left, top;

      &:hover {
        color: $color-gray-100;
      }

      &.hovered {
        color: $color-gray-100;
      }
    }

    .options {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      position: fixed;
      left: 0;
      top: 0;
      padding: 4px 0;
      background-color: $color-white;
      border-radius: 5px;
      box-shadow: 1px 2px 5px 0 rgba($color-gray-60, 0.5);
      border: 1px solid #CCD7E0;
      min-width: 102px;
      min-height: 10px;
      z-index: 25;
      will-change: left, top;
    }

    ::v-deep {
      &.disabled {
        .button {
          &:hover {
            @include svg(#CBD3D9);
          }
        }
      }

      .button {
        @include svg(#CBD3D9);

        &:hover, &.hovered {
          @include svg($color-gray-100)
        }
      }
    }
  }
</style>

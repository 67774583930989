<template lang="html">
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0013 1.66663C5.4013 1.66663 1.66797 5.39996 1.66797 9.99996C1.66797 14.6 5.4013 18.3333 10.0013 18.3333C14.6013 18.3333 18.3346 14.6 18.3346 9.99996C18.3346 5.39996 14.6013 1.66663 10.0013 1.66663ZM10.418 15.8333H9.58464C9.35464 15.8333 9.16797 15.6466 9.16797 15.4166V14.5833C9.16797 14.3533 9.35464 14.1666 9.58464 14.1666H10.418C10.648 14.1666 10.8346 14.3533 10.8346 14.5833V15.4166C10.8346 15.6466 10.648 15.8333 10.418 15.8333ZM12.5596 9.37496L11.8096 10.1416C11.2096 10.75 10.8346 11.25 10.8346 12.5H9.16797V12.0833C9.16797 11.1666 9.54297 10.3333 10.143 9.72496L11.1763 8.67496C11.5571 8.30496 11.7471 7.75663 11.6371 7.17246C11.5246 6.57496 11.0638 6.06663 10.4796 5.90079C9.55797 5.63913 8.6988 6.16663 8.42464 6.96079C8.31797 7.26996 8.05797 7.49996 7.73047 7.49996H7.48797C7.0038 7.49996 6.66547 7.02996 6.80047 6.56496C7.15797 5.33829 8.2013 4.40413 9.48797 4.20579C10.758 4.00996 11.9621 4.66329 12.7155 5.70496C13.6988 7.06496 13.4088 8.52579 12.5596 9.37496Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconQuestionMarkCircle'
}
</script>

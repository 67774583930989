export const paymentStatuses = [
  { key: 'Ожидается подтверждение операции', value: 'AUTHORIZED' },
  { key: 'Оплата прошла успешно', value: 'CONFIRMED' },
  { key: 'Отмена операции', value: 'REVERSED' },
  { key: 'Возврат средств', value: 'REFUNDED' },
  { key: 'Частичный возврат средств', value: 'PARTIAL_REFUNDED' },
  { key: 'Ошибка списания средств', value: 'REJECTED' }
]

export default {
  paymentStatuses
}

import api from '@/api'

export const state = () => ({
  user: {}
})

export const getters = {
  isAdmin (state) {
    return state.user.role === 'ADMIN'
  },
  isSimpleUser (state) {
    return state.user.role === 'SIMPLE_USER'
  }
}

export const actions = {
  // POST
  whoami ({ commit }) {
    return api.post('/auth/whoami').then(res => {
      commit('SET_USER', res)
    })
  },
  requestResetPassword (_, data = {}) {
    return api.post('/auth/request-password-reset', {
      data,
      options: {
        sendSessionToken: false
      }
    })
  },
  resetPassword (_, data = {}) {
    return api.post('/auth/reset-password', {
      data,
      options: {
        sendSessionToken: false
      }
    })
  },
  login (_, data) {
    return api.post('/auth/login', {
      data,
      options: {
        sendSessionToken: false
      }
    })
  },
  register (_, data) {
    return api.post('/auth/register', {
      data,
      options: {
        sendSessionToken: false
      }
    })
  },
  confirmEmail (_, data) {
    return api.post('/auth/confirm-email', {
      data,
      options: {
        sendSessionToken: false
      }
    })
  },
  logout () {
    return api.post('/auth/logout')
  }
}

export const mutations = {
  SET_USER (state, value) {
    state.user = value
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}

import auth from './auth'
import account from './account'
import client from './client'
import transactions from './transactions'

export const modules = {
  auth,
  account,
  client,
  transactions
}

export default {
  modules,
  namespaced: true
}

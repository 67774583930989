<template lang="pug">
  .table-component(:class="{ darker, border }")
    .content-section(v-if="!items.length && !custom")
      span Нет данных для отображения

    .table-wrapper(v-if="items.length || custom")
      transition(name="fade")
        .loading.flex.center(v-if="loading")
          .loader
      .header-prepend(v-if="$slots['header-prepend']")
        slot(name="header-prepend")
      table.table
        thead
          slot(name="headers")
            tr
              template(v-for="header in headers")
                th(
                  v-if="!header.disabled"
                  :class="{ 'sortable': header.sortable }")
                  slot(:name="`header.${header.column}`" :header="header")
                    span(@click="setOrderBy(header)") {{ header.caption }}
                      iconSort.sort-icon(v-if="header.sortable" :class="{'desc': isDesc(header.column)}")
        tbody
          slot(name="items")
            tr(v-for="(item, i) in items" :key="i")
              td(
                v-for="(header, j) in headers"
                :key="j") {{ (header.resolveValue || function (v) { return v })(getValue(header.column, item)) }}

    .table-pagination(v-if="showPagination && pagination.total > 10")
      Pagination(v-model="localPagination" :per-page-options="perPageOptions")
</template>

<script>
import Pagination from '../pagination'

export default {
  name: 'Table',
  components: {
    Pagination
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    headers: {
      type: Array,
      default: () => []
    },
    noItemsText: {
      type: String,
      default: ''
    },
    showPagination: {
      type: Boolean,
      default: true
    },
    pagination: {
      type: Object,
      default: () => ({})
    },
    perPageOptions: {
      type: Array,
      default: undefined
    },
    custom: Boolean,
    darker: Boolean,
    loading: Boolean,
    border: Boolean
  },
  data () {
    return {
      descending: null,
      sortIndex: 'asc',
      currentSort: ''
    }
  },
  computed: {
    localPagination: {
      get () {
        return this.pagination
      },
      set (value) {
        this.$emit('update:pagination', value)
      }
    }
  },
  methods: {
    isDesc (column) {
      return this.currentSort === column && !this.descending
    },
    getValue (key, item) {
      // Какая-то тупая ошибка выскакивает даже если слот переопределен
      let parts = (key + '').split('.'),
        rv = item
      try {
        while (parts.length) rv = rv[parts.shift()]
        return rv
      } catch (e) {
        return ''
      }
    },
    setOrderBy (header) {
      // Костыль чтобы можно было отследить изменение pagination
      if (header.sortable) {
        this.currentSort = header.column
        this.descending = !this.descending
        this.descending ? this.sortIndex = 'desc' : this.sortIndex = 'asc'
        this.$emit('sort', { orderBy: header.column, sortIndex: this.sortIndex })
      }
    }
  }
}
</script>

<style lang="scss">
  .table-component {
    .sortable {
      @include svg($color-white);
      * {
        &:hover {
          color: #E3E7EA;
          @include svg(#E3E7EA)
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  .table-component {
    margin-bottom: 0;
    overflow: visible;
    position: relative;

    .table-pagination {
      margin-top: 20px;
    }

    .content-section {
      padding: 14px 16px 14px 16px;
      background-color: #FFFFFF;
      box-shadow: 0 0 0 1px #e4eaef inset;
      border-radius: 4px;

      span {
        font-size: 16px;
        line-height: 22px;
      }
    }

    .table-wrapper {
      position: relative;
      border-radius: 4px;

      &::-webkit-scrollbar {
        width: 8px;
        background-color: rgba(60, 66, 77, 0.1);
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-dark-blue-100
      }

      .header-prepend {
        padding: 8px 16px;
        background-color: #DADADA;
      }

      .loading {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color-white, 0.6);
        z-index: 3;

        .loader {
          width: 50px;
          height: 50px;
          margin: 2rem;
          border-radius: 50%;
          border: 0.3rem solid $color-blue-80;
          border-top-color: rgba($color-blue-80, 0.2);
          animation: 1s spin infinite ease;
          @keyframes spin {
            from {
              transform: rotate(0);
            }
            to {
              transform: rotate(360deg);
            }
          }
        }
      }

      .table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;

        tbody {
          border-radius: 0 0 4px 4px;

          td {
            background-color: $color-white;
          }
        }

        th, td {
          vertical-align: top;
          font-size: 14px;
        }

        th {
          padding: 12px 16px 12px 16px;
          line-height: 1;
        }

        /*td {*/
        /*  padding: 14px 16px;*/
        /*}*/

        tr {
          width: 100%;

          .sort-icon {
            display: inline-block;
            width: 8px;
            height: 8px;
            margin-left: 8px;
            cursor: pointer;
          }

          &:not(:last-child) {
            td:first-child {
              border-radius: 0;
            }

            td:last-child {
              border-radius: 0;
            }
          }
        }

        th {
          background: $color-gray-20;
          color: $color-gray-100;
          text-align: left;
          white-space: nowrap;
          line-height: 1;
          font-weight: 300;
          cursor: default;

          &.sortable {
            user-select: none;
            transition: color 0.3s ease;

            .desc {
              transition: transform 0.3s ease;
              transform: rotateX(180deg);
            }

            * {
              cursor: pointer;
              transition: color 0.3s ease;
            }

            svg {
              transition: transform 0.3s ease;
            }
          }

          &:first-child {
            border-top-left-radius: 4px;
          }

          &:last-child {
            border-top-right-radius: 4px;
          }
        }

        td {
          word-wrap: break-word;
          line-height: 1;
          padding: 20px 20px;

          &:first-child {
            border-radius: 0 0 0 4px;
            box-shadow: 1px -1px 0 0 #E4EAEF inset,
            -1px 0 0 0 #E4EAEF inset;
          }

          &:not(:last-child):not(:first-child) {
            box-shadow: -1px -1px 0 0 #E4EAEF inset,
            -1px 0 0 0 #E4EAEF inset;
          }

          &:last-child {
            border-radius: 0 0 4px 0;
            box-shadow: -1px -1px 0 0 #E4EAEF inset;
          }

          &:only-child {
            border-radius: 0 0 4px 0;
            box-shadow: -1px -1px 0 0 #E4EAEF inset,
            1px 0 0 0 #E4EAEF inset;
          }
        }
      }
    }

    &.darker {
      .table-wrapper {
        .table {
          thead {
            border-radius: 4px;
            overflow: hidden;
          }

          th {
            min-height: 44px;
            padding: 12px 16px;
            vertical-align: middle;
            background-color: #97A8B3;
            border-color: #97A8B3;
            color: $color-white;
            overflow: visible;
            font-size: 14px;
            line-height: 20px;
          }

          tbody {
            tr {
              &:hover td {
                background-color: #FAFBFC;
              }

              &:not(:last-child) {
                td:first-child {
                  border-radius: 0;
                }

                td:last-child {
                  border-radius: 0;
                }
              }

              &.expand-content {
                &:last-child {
                  td {
                    position: relative;
                    top: -1px;

                    &:first-child,
                    &:last-child,
                    &:not(:last-child):not(:first-child) {
                      border-radius: 0 0 4px 4px;
                      box-shadow: 1px -1px 0 0 #E4EAEF inset,
                      -1px 0 0 0 #E4EAEF inset;
                    }
                  }
                }

                &:not(:last-child) {
                  td {
                    &:first-child, &:last-child, &:not(:last-child):not(:first-child) {
                      border-radius: 0;
                      box-shadow: 1px -1px 0 0 #E4EAEF inset,
                      -1px 0 0 0 #E4EAEF inset,
                      0 -2px 0 -1px $color-white;
                    }
                  }
                }
              }
            }

            td {
              padding: 14px 16px;
              word-break: break-all;
              word-wrap: break-word;
              font-size: 14px;
              line-height: 24px;
              border-color: transparent;
              word-break: break-all;
              word-break: break-word;

              &:first-child {
                box-shadow: 1px -1px 0 0 #E4EAEF inset;
              }

              &:not(:last-child):not(:first-child) {
                box-shadow: 0 -1px 0 0 #E4EAEF inset;
              }

              &:last-child {
                box-shadow: -1px -1px 0 0 #E4EAEF inset;
              }
            }
          }
        }
      }
    }

    &.border {
      tr:first-child {
        th {
          &:first-child {
            box-shadow: 0 0 0 1px #8b9da8 inset;
          }

          &:not(:first-child) {
            box-shadow: -1px 0 0 0 #8b9da8 inset,
            0 -1px 0 0 #8b9da8 inset,
            0 1px 0 0 #8b9da8 inset;
          }
        }
      }

      tr:not(:first-child) {
        th {
          &:first-child {
            box-shadow: -1px 0 0 0 #8b9da8 inset,
            0 -1px 0 0 #8b9da8 inset;
          }

          &:not(:first-child) {
            box-shadow: -1px 0 0 0 #8b9da8 inset,
            0 -1px 0 0 #8b9da8 inset !important;
          }
        }
      }

      tr {
        td {
          padding: 20px 16px 20px 16px;

          &:first-child {
            box-shadow: 1px 0 0 0 #E4EAEF inset,
            -1px 0 0 0 #E4EAEF inset,
            0 -1px 0 0 #E4EAEF inset !important;
          }

          &:not(:first-child) {
            box-shadow: -1px 0 0 0 #E4EAEF inset,
            0 -1px 0 0 #E4EAEF inset !important;
          }
        }
      }
    }
  }
</style>

<template lang="pug">
  .login-form-component
    span.title Вход в Weasy

    ValidationObserver(ref="validation" tag="div")
      form(@submit.prevent="submit")
        .field
          ValidationProvider(
            :rules="{required: true, email: true}"
            name="e-mail"
            v-slot="{ errors }"
            mode="lazy"
            tag="div")
            Input(
              v-model="form.email"
              placeholder="Email"
              :error="!!errors.length")
        .field
          ValidationProvider(
            :rules="{required: true}"
            name="Пароль"
            v-slot="{ errors }"
            mode="lazy"
            tag="div")
            Input(
              v-model="form.password"
              :error="!!errors.length"
              placeholder="Пароль"
              password)
        .flex.j-center
          Button.button-submit(
            @click="submit"
            :loading="isLoading"
            size="block"
            role="submit") Войти

    .button-group
      router-link(to="/registration") Зарегистрироваться
      Button.button-reset-password(@click="openForgotForm" text) Восстановить пароль
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)

export default {
  name: 'LoginForm',
  data () {
    return {
      isLoading: false,
      form: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    ...mapActions('api/auth', ['login']),

    async submit () {
      const isValid = await this.$refs.validation.validate()
      const isEmailIncorrect = this.$refs.validation.fields['e-mail'].failed
      if (this.form.email.length && isEmailIncorrect) {
        this.$notify.add('Введен некорректный e-mail адрес.')
      }
      if (!isValid) return

      this.isLoading = true

      const data = {
        email: this.form.email,
        password: this.form.password
      }

      try {
        await this.login(data)

        const autoLogoutUrl = this.$cookies.get('auto_logout_url')

        if (autoLogoutUrl) {
          this.$cookies.remove('auto_logout_url')
          await this.$router.push(autoLogoutUrl)
        } else {
          await this.$router.push('/')
        }
      } catch (err) {
        let errorMessage = 'Возникла ошибка. Пожалуйста, попробуйте еще раз'
        if (err.response.status === 401) {
          errorMessage = 'Неправильный логин или пароль'
        }
        this.$notify.add({
          content: errorMessage
        })
      } finally {
        this.isLoading = false
      }
    },
    openForgotForm () {
      this.$emit('openForgotForm')
    }
  },
  mounted () {
    const sessionCookie = this.$cookies.get('session')

    if (sessionCookie) this.$router.push('/')
  }
}
</script>

<style lang="scss" scoped>
  .login-form-component {
    padding: 30px 48px;
    max-width: 456px;
    width: 100%;
    background-color: $color-white;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(153, 169, 181, 0.2);

    .title {
      display: block;
      text-align: center;
      font-size: 24px;
      line-height: 32px;
      font-weight: 400;
      margin-bottom: 30px;
    }

    .field {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .button-submit {
      margin-bottom: 20px;
      max-width: 104px;
    }

    .button-group {
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        font-size: 15px;
      }

      .button-reset-password {
        margin-left: 20px;
      }
    }
  }
</style>

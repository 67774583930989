<template lang="pug">
  .delete-integration-modal-component
    Modal(
      :value="show"
      title="Действительно удалить?"
      width="446px"
      @close="close"
      :loading="isLoading")

      template(slot="actions")
        .flex
          Button(
            @click="submit"
            :loading="isLoading"
            size="block") Да
          Button(
            @click="close"
            :disabled="isLoading"
            size="block"
            color="secondary") Нет
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ModalDeleteIntegration',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    show: Boolean
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    ...mapActions('api/account', ['setCallback']),

    async submit () {
      this.isLoading = true

      const data = {
        senderName: this.item.senderName,
        accountId: this.item.id
      }

      try {
        await this.setCallback({ data })
        this.close()
        this.$emit('update')
        this.$notify.add({
          content: 'Интеграция удалена',
          type: 'success'
        })
      } catch (err) {
        console.warn('Error', err)
        this.$notify.add('Произошла ошибка. Пожалуйста, попробуйте еще раз.')
      } finally {
        this.isLoading = false
      }
    },
    close () {
      this.$emit('update:show', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .delete-integration-modal-component {
    .fields {
      .field {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
</style>

export const modules = [
  {
    name: 'auth',
    routes: require('./auth/routes').default,
    store: require('./auth/store').default
  },
  {
    name: 'accounts',
    routes: require('./accounts/routes').default
  },
  {
    name: 'integrations',
    routes: require('./integrations/routes').default
  },
  {
    name: 'transactions',
    routes: require('./transactions/routes').default
  }
]

<template lang="pug">
  .api-auth-modal-component
    Modal(
      :value="show"
      :title="getModalTitle"
      width="666px"
      @close="close"
      :loading="isLoading")
      template(v-if="isDataLoading")
        .flex.center
          Preloader(static)

      template(v-if="!isDataLoading && !isAccountConnected")
        .qr-code
          img(:src="`data:image/png;base64, ${code}`")

        .refresh-code
          Button(@click="loadQrCode" size="s") Обновить QR

        .instructions
          ol
            li Откройте <strong>WhatsApp Business</strong> на своем телефоне
            li Для Android нажмите <strong>Меню</strong>
              IconSettingsAndroid
              | для iOS нажмите <strong>Настройки</strong>
              IconSettingsIos
            li Выберите <strong>Связанные устройства</strong>
            li Нажмите <strong>Привязка устройства</strong>
            li Наведите свой телефон на этот экран, чтобы считать код

        a.link-faq.link-underline(href="https://faq.weasy.pro/" target="_blank") Подробная инструкция в FAQ

      template(v-if="!isDataLoading && isAccountConnected")
        .links-info-section
          span.subtitle Полезное в нашем FAQ
          ul
            li(v-for="(link, i) in faqLinks" :key="i")
              a.link-underline(:href="link.href" target="_blank") {{ link.title }}
        Checkbox(v-model="dontShowFaq") Больше не показывать
        template(slot="actions")
          Button.button-apply(@click="close" fullwidth) Ок
</template>

<script>
import { mapActions } from 'vuex'
import IconSettingsAndroid from './icons/IconSettingsAndroid'
import IconSettingsIos from './icons/IconSettingsIos'

export default {
  name: 'ModalApiAuth',
  components: {
    IconSettingsAndroid,
    IconSettingsIos
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    show: Boolean
  },
  data () {
    return {
      isDataLoading: true,
      isLoading: false,
      isAccountConnected: false,
      dontShowFaq: false,
      requestInterval: null,
      code: '',
      faqLinks: [
        {
          href: 'https://faq.weasy.pro/integracii/integraciya-s-aimylogic',
          title: 'Интеграция с ботом Aimylogic'
        },
        {
          href: 'https://api.weasy.pro/',
          title: 'Наш API'
        },
        {
          href: 'https://faq.weasy.pro/integracii/integraciya-s-bitriks-24',
          title: 'Интеграция с Битрикс24'
        },
        {
          href: 'https://faq.weasy.pro/blokirovka-akkaunta-i-chto-delat/kak-predotvratit-blokirovku-whatsapp-akkaunta',
          title: 'Как предотвратить блокировку аккаунта со стороны WhatsApp'
        }
      ]
    }
  },
  watch: {
    show () {
      if (this.show) {
        this.code = ''
        this.isAccountConnected = false
        if (localStorage.getItem('disable-accounts-faq-message')) {
          this.dontShowFaq = true
        }
        this.loadQrCode()
      } else {
        clearInterval(this.requestInterval)
      }
    }
  },
  computed: {
    getModalTitle () {
      return this.isAccountConnected ? 'Аккаунт успешно подключен!' : 'Подключить аккаунт'
    }
  },
  methods: {
    ...mapActions('api/account', ['getAccountQrCode', 'getAccountById']),

    async loadItemInfo () {
      try {
        const id = this.item.id
        const res = await this.getAccountById(id)
        if (res.partnerStatus === 'authorized') {
          clearInterval(this.requestInterval)
          this.$notify.add({
            content: 'Аккаунт успешно авторизован!',
            type: 'success'
          })
          if (this.dontShowFaq) {
            this.close()
          } else {
            this.isAccountConnected = true
          }
          this.$emit('updateAccounts')
        }
      } catch (err) {
        console.warn('Error', err)
      }
    },
    async loadQrCode () {
      this.isDataLoading = true
      const id = this.item.id

      try {
        const res = await this.getAccountQrCode(id)

        this.requestInterval = setInterval(() =>{
          this.loadItemInfo()
        }, 2000)

        if (res.type === 'alreadyLogged') {
          this.$notify.add({
            content: 'Аккаунт уже авторизован',
            type: 'info'
          })
          this.close()
        } else {
          this.code = res.message
        }
      } catch (err) {
        console.warn('Error', err)
        this.$notify.add('Не удалось получить QR-код, попробуйте позже')
        this.close()
      } finally {
        this.isDataLoading = false
      }
    },
    close () {
      this.$emit('update:show', false)

      this.dontShowFaq
        ? localStorage.setItem('disable-accounts-faq-message', 'true')
        : localStorage.removeItem('disable-accounts-faq-message')
    }
  },
  beforeDestroy () {
    clearInterval(this.requestInterval)
  }
}
</script>

<style lang="scss" scoped>
  .api-auth-modal-component {
    .qr-code {
      display: flex;
      justify-content: center;
      height: 264px;
      margin-bottom: 16px;
    }

    .refresh-code {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }

    .instructions {
      margin-top: 16px;
      margin-bottom: 20px;

      ol {
        margin: 0;
        font-size: 15px;
        line-height: 22px;
        padding-left: 26px;

        li {
          font-size: 15px;
          line-height: 22px;
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0;
          }

          strong {
            font-weight: 500;
          }

          svg {
            width: 24px;
            vertical-align: middle;
            margin: 0 5px -2px;
          }
        }
      }
    }

    .link-faq {
      display: inline-block;
      margin-left: 10px;
      margin-bottom: 7px;
      line-height: 1;
    }

    .links-info-section {
      padding: 20px;
      margin: 0 -20px 21px -20px;
      background-color: $color-light-blue-10;

      .subtitle {
        display: block;
        margin-bottom: 13px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }

      ul {
        margin: 0;
        padding-left: 22px;

        li {
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          a {
            display: inline-block;
            font-size: 15px;
            line-height: 1;
          }
        }
      }
    }

    .button-apply {
      max-width: 192px;
    }

    ::v-deep {
      .modal-component .modal-container {
        .modal-header {
          padding: 20px;
        }

        .modal-content {
          padding: 0 20px 13px 20px;
        }

        .modal-actions {
          padding: 0 20px 20px 20px;
        }
      }
    }
  }
</style>
